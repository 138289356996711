import { createStore, combineReducers } from 'redux';
// import results from './reducers/results';
import elementRequiredReducer from './reducers/elementRequiredReducer'
import priceCheckReducer from './reducers/defaultPriceCheckReducer'

const reducer = combineReducers({
    // results,
    elementRequiredReducer,
    priceCheckReducer
});

const store = createStore(reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
