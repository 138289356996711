import React from 'react';
// import './TitleOnlySave.css';

class TitleOnlySave extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: false};
  }

  render() {
    return(
      <div className='row col-12' name='form_change_name'>
        <div className='col-md-8 mb-4'>
            <hr/>
            <h5 className='m-0'>{this.props.title}</h5>
            <small className='text-secondary'>{this.props.description}</small>
          </div>
          <div id='div_send_attribute' className='col-md-4'>
            <button id='btn_send_name' className='btn btn-primary btn-block mt-3' type='button' onClick={this.props.check_data}>
              <span className='text-white mr-1'>Guardar</span>
            </button>
          </div>
      </div>
    );
  }
}
export default TitleOnlySave;
