import React from 'react';
import Title from '../../components/Title';
import SelectAtribute from '../../components/SelectAttribute';
import InputTextAttribute from '../../components/InputTextAttribute';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux'

class Price extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: false
    };
  }

  render() {
    let {purchase_currency, purchase_price, selling_price, standar_cost} = this.props.requiredPrices

    return(
      <div className='row'>
        <div className='col-md-12'>
          <Title title='COSTOS'/>
        </div>

        <div className='col-md-6'>
          <SelectAtribute id='attributes_select_purchase_currency' options={['DL','EUR','PS']} 
          title='Moneda' name='purchase_currency' 
          required={purchase_currency}
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          description='Moneda de compra, ya sea Dólar DL, Euro EUR y Peso Chileno PS'
          flexline_name = 'analisisproducto22'/>
        </div> 

        <div className='col-6'>
          <InputTextAttribute title='Precio Compra' name='purchase_price' id='id_input_purchase_price' disabled={this.state.enabled} 
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          type='number' price={true} required={purchase_price}
          description='Indica el valor de compra en moneda extranjera.'
          flexline_name='analisisproducto23'/>
        </div>

        <div className='col-6'>
          <InputTextAttribute 
          title='Precio Venta' name='selling_price' id='id_input_selling_price'
          type='number' max='10000000' min='0' price={true}
          required={selling_price}
          description='Valor de venta de referencia.'
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          flexline_name='precioventa'/>
        </div>

        <div className='col-md-6'>
          <InputTextAttribute 
          title='Costo Estandar' name='standar_cost' id='id_input_standar_cost'  
          type='number' max='10000000' min='0' price={true} 
          required={standar_cost}
          cost_change_confirm={true}
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          description='Es un valor de costo referencial que es aplicado en forma manual, esto se hace para tener un valor de un producto que aún no ha sido costeado.'
          flexline_name='costoestandar'/>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requiredPrices: state.elementRequiredReducer.prices
})

const mapDispatchToProps = (dispach) => ({})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Price)

export default component;
