import React, { useEffect, useState } from 'react';
import es from '../es.json';
import { Link } from "react-router-dom";
import { massivePriceListDetail } from "../api/APIGet"
import ReactLoading from 'react-loading';
import './ProductPricesDetail.css'

const ProductPricesDetail = (props) => {

  const [productPrices, setProductPrices] = useState([])
  const [loadDetail, setLoadDetail] = useState(true)

  useEffect(() => {
    retrieveProductPrices()
  }, [])

  const retrieveProductPrices = () => {
    setLoadDetail(true)
    massivePriceListDetail(props.match.params['id']).then(res => {
      if(res.data) {
        setProductPrices(res.data)
        setLoadDetail(false)
      }
      else {
        setProductPrices([])
      }
    })
  }

  const processState = (change_state) => {
    if(change_state == 'rejected') return es['Common']['failed']

    return es['Common']['processed']
  }

  return (
    <div>
      { loadDetail ? 
          <div id='product_prices_detail_loader' className='text-center d-flex mt-5 justify-content-center'>
            <div className='mt-5'>
              <ReactLoading delay={2000 /1000} color={'#544f92'} type='spokes'/>
            </div>
          </div>
      :
        <div className='product-prices-detail-layout'>
          <div className='return-button'>
            <Link to={{ pathname:`/products_prices` }}>
              <button  className='btn btn-primary' type='submit'>
                { es['Common']['back'] }
              </button>
            </Link>
          </div>
          <div className='title'>
            <h3> 
              <span>
                { es['Common']['upload_detail'] }
              </span>
              <span className='black-text'>
                { productPrices['attributes']['name'] }
              </span>
            </h3>
          </div>
          <div className='approved-changes'>
            <h2 className='black-text'> { productPrices['attributes']['automatic_approve_count'] } </h2>
            <h4> { es["ProductPriceLists"]["approved-changes"] } </h4>
          </div>
          <div className='pending-changes'>
            <h2 className='black-text'> { productPrices['attributes']['pending_approve_count'] } </h2>
            <h4> { es["ProductPriceLists"]["pending-changes"] } </h4>
          </div>
          <div className='rejected-changes'>
            <h2 className='black-text'> { productPrices['attributes']['rejected_count'] } </h2>
            <h4> { es["ProductPriceLists"]["rejected-changes"] } </h4>
          </div>
          <div className='detail-table'>
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'> { es["Common"]["sku"] } </th>
                    <th scope='col'> { es["ProductPriceLists"]["modified_price_list"] } </th>
                    <th scope='col'> { es["ProductPriceLists"]["modified_price"] } </th>
                    <th scope='col'> { es["ProductPriceLists"]["change_state"] } </th>
                    <th scope='col'> { es["ProductPriceLists"]["process_state"] } </th>
                  </tr>
                </thead>
                <tbody>
                {
                      productPrices['attributes']['invalid_changes'].length > 0 ?
                        productPrices['attributes']['invalid_changes'].map((data) => {
                          let warning_class_sku = data['error'].includes('sku') ? 'warning-text' : '-'
                          let warning_class_pl = data['error'].includes('sku') ? '-' : 'warning-text'
                          return(
                            <tr title= { es["Common"][data['error']]} id={ `row_${data['sku']}` }>
                              < td className={warning_class_sku} > { data['sku'].split('_')[0] } </td>
                              < td className={warning_class_pl} > { data['field_name'] } </td>
                              < td> { data['new_value'] } </td>
                              < td> { es['Common']['rejected'] } </td>
                              < td> { es['Common']["processed"] } </td>
                            </tr>  
                          )
                        })
                    :
                      null
                  }
                  {
                      productPrices['attributes']['price_changes'].length > 0 ?
                        productPrices['attributes']['price_changes'].map((data) => {
                          return(
                            <tr id={ `row_${data['sku']}` }>
                              <td> { data['attributes']['sku'] } </td>
                              <td> { data['attributes']['field_name'] } </td>
                              <td> { data['attributes']['new_value'] } </td>
                              <td> { es['Common'][data['attributes']['aasm_state']] } </td>
                              <td> { processState(data['attributes']['aasm_state']) } </td>
                            </tr>  
                          )
                        })
                    :
                      null
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ProductPricesDetail;
