import React from 'react';
import Required from '../../components/Required.js'
class InputTextAttributeFull extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: true};
  }

  componentDidUpdate(){
    if(this.props.value_gloss!==undefined){
      var name = document.getElementById(this.props.id);
      name.value = this.props.value_name===''? this.props.value_gloss : this.props.value_name;
    }
  }

  changeText(){
    var max_print_char = 50;
    var name = document.getElementById(this.props.id);
    var value = name.value;
    let prefix = this.props.selectedKindState == 'kit' ? `${this.props.selectedKindState}_` : ''
    if(document.getElementById(this.props.id_second)){
      document.getElementById(this.props.id_second).value = `${prefix}${value.substring(0,parseInt(this.props.maxlength_second))}`
      document.getElementById('print_37Char'+this.props.id).innerText = value.substring(0,max_print_char);

    }
    
    if(this.props.required){
      this.props.calculatePercentOfInput()
    }
  }

  render() {
    return(
      <div className='row Product-show-InputTextAttributeFull mb-3'>
        <div className='col-md-2'>
          <p  className='mt-2 bolder-text' data-toggle='tooltip' data-placement='right' title={this.props.description_name}>
            {this.props.title}
            <Required required={this.props.required} />
          </p>
          <small className='text-secondary'>
            {this.props.flexline_name}
          </small>
        </div>
        <div className='col-md-10 form-group'>
          <input type='text' name={`${this.props.selectedKindState}_${this.props.name}`} className='form-control text-uppercase' id={this.props.id} 
          placeholder={this.props.title} disabled={this.props.disabled} maxLength={this.props.maxlength} 
          onKeyUp={this.changeText.bind(this)} required={this.props.required}/>
        </div>
      </div>
    );
  }
}
export default InputTextAttributeFull;
