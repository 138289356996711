import React, { useState, useEffect } from 'react';
import es from '../es.json';
import { connect } from 'react-redux'

const Search = ({ families, subfamilies, categories, id, getProductsData, filterCategoryData, getInitialFilters }) => {
  const [category, setCategory] = useState('');
  const [family, setFamily] = useState('');
  const [subfamily, setSubfamily] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([])
  const [familyOptions, setFamilyOptions] = useState([])
  const [subfamilyOptions, setSubfamilyOptions] = useState([])

  useEffect(()=>{
    let new_families = families.map((element,key) => {
      return( element.trim().length!==0? <option defaultValue={family == element} value={element} key={key}>{element}</option> :  null)
    });  
    let new_subfamilies = subfamilies.map((element, key) => {
      return( element.trim().length!==0? <option defaultValue={subfamily == element} value={element} key={key}>{element}</option> :  null)
    });
    let new_categories = categories.map((element, key) => {
      return( element.trim().length!==0? <option defaultValue={category == element} value={element} key={key}>{element}</option> :  null)
    });
    setCategoryOptions(new_categories)
    setFamilyOptions(new_families)
    setSubfamilyOptions(new_subfamilies)
  },[families,subfamilies,categories])

  useEffect(()=>{
    getInitialFilters()
  },[])

  useEffect(() => {
    filterCategoryData(category,family,subfamily)
    filterProduct()
  },[category,family,subfamily])

  const filterProduct = () => {
    var businessFilter = document.getElementById('businessFilter').value;
    var familyFilter = document.getElementById('familyFilter').value;
    var subFamilyFilter = document.getElementById('subFamilyFilter').value;
    var categoriesFilter = document.getElementById('categoriesFilter').value;
    var typesFilter = document.getElementById('typesFilter').value;
    let stateFilter = document.getElementById('stateFilter').value;
    var search_category = document.getElementById('search_category').value;
    getProductsData(businessFilter, familyFilter, subFamilyFilter, categoriesFilter, typesFilter, stateFilter, search_category);
  }

  const handleFilterCategory = (e) => {
    let selectedValue = e.target.value
    let selectedCategory = e.target.dataset.name
    switch(selectedCategory){
      case 'clasificador2':
        setCategory(selectedValue);
        break;
      case 'clasificador3':
        setFamily(selectedValue);
        break
      case 'clasificador4':
        setSubfamily(selectedValue)  
        break
    }
  }

  const handleSearchProduct = (e) => {
    if (e.key === 'Enter') {
      var businessFilter = document.getElementById('businessFilter').value;
      var familyFilter = document.getElementById('familyFilter').value;
      var subFamilyFilter = document.getElementById('subFamilyFilter').value;
      var categoriesFilter = document.getElementById('categoriesFilter').value;
      var typesFilter = document.getElementById('typesFilter').value;
      let stateFilter = document.getElementById('stateFilter').value;
      var search_category = document.getElementById('search_category').value;
      getProductsData(businessFilter, familyFilter, subFamilyFilter, categoriesFilter, typesFilter, stateFilter, search_category);
    }
  }

  const resetFilterData = () => {
    let businessFilter = document.getElementById('businessFilter').value = ''
    let familyFilter = document.getElementById('familyFilter').value = ''
    let subFamilyFilter = document.getElementById('subFamilyFilter').value = ''
    let categoriesFilter = document.getElementById('categoriesFilter').value = ''
    let typesFilter = document.getElementById('typesFilter').value = ''
    let stateFilter = document.getElementById('stateFilter').value = ''
    var search_category = document.getElementById('search_category').value = ''
    getProductsData(businessFilter, familyFilter, subFamilyFilter, categoriesFilter, typesFilter, stateFilter, search_category);
    setCategory('')
    setFamily('')
    setSubfamily('')
    getInitialFilters()
  }

  return(
    <div className='Product-Seach p-2' id={id}>
      <div className='row mr-0 ml-0'>
        <div className='col-md-3 pt-2'>
          <small>Negocio</small>
        </div>
        <div className='col-md-3 pt-2 pl-0 pr-0'>
          <select id='businessFilter' className='form-control form-control-sm' onChange={filterProduct} data-name='clasificador1'>
            <option value=''>Ver Todo</option>
            <option value='COMPONENTES'>COMPONENTES</option>
            <option value='ORGANIZACION'>ORGANIZACION</option>
            <option value='ELECTRO Y MENAJE'>ELECTRO Y MENAJE</option>
            <option value='FABRICADOS'>FABRICADOS</option>
            <option value='INSTALADOS'>INSTALADOS</option>
            <option value='SERVICIOS'>SERVICIOS</option>
          </select>
        </div>
        <div className='col-md-3 pt-2'>
          <small>Categoría</small>
        </div>
        <div className='col-md-3 pt-2 pl-0 pr-0'>
          <select id='categoriesFilter' className='form-control form-control-sm' onChange={handleFilterCategory} data-name='clasificador2'>
            <option value=''>Ver Todo</option>
            {categoryOptions}
          </select>
        </div>
        <div className='col-md-3 pt-2'>
          <small>Familia</small>
        </div>
        <div className='col-md-3 pt-2 px-0'>
          <select id='familyFilter' className="form-control form-control-sm" onChange={handleFilterCategory} data-name='clasificador3'>
            <option value=''>Ver Todo</option>
            {familyOptions}
          </select>
        </div>
        <div className='col-md-3 pt-2'>
          <small>Sub-Familia</small>
        </div>
        <div className='col-md-3 pt-2 pl-0 pr-0'>
          <select id='subFamilyFilter' className='form-control form-control-sm' onChange={handleFilterCategory} data-name='clasificador4'>
            <option value=''>Ver Todo</option>
            {subfamilyOptions}
          </select>
        </div>
        <div className='col-md-3 pt-2'>
          <small>Tipo</small>
        </div>
        <div className='col-md-3 pt-2 pl-0 pr-0'>
          <select id='typesFilter' className='form-control form-control-sm' onChange={filterProduct}>
            <option value=''> { es["Components"]["Search"]["all_options"] } </option>
            <option value='EXISTENCIA'> { es["Components"]["Search"]["existence"] } </option>
            <option value='SERVICIOS DE VENTA'> { es["Components"]["Search"]["sell_service"] } </option>
            <option value='SIMPLE'> { es["Components"]["Search"]["simple_product"] } </option>
            <option value='CONFIGURABLE'> { es["Components"]["Search"]["custom_product"] } </option>
            <option value='PACK'> { es["Components"]["Search"]["pack_product"] } </option>
            <option value='KIT'> { es["Components"]["Search"]["kit_product"] } </option>
          </select>
        </div>
        <div className='col-md-3 pt-2'>
          <small>Estado</small>
        </div>
        <div className='col-md-3 pt-2 pl-0 pr-0'>
          <select id='stateFilter' className='form-control form-control-sm' onChange={filterProduct}>
            <option value=''>Ver Todo</option>
            <option value='created'>Creado</option>
            <option value='published'>Publicado</option>
            <option value='pending'>Pendiente</option>
          </select>
        </div>
      </div>
      <div className='row'>
        <div className='col text-right mt-2'>
          <button className='btn btn-primary' onClick={ resetFilterData }>
            Limpiar filtros
          </button>
        </div>
      </div>
      <div className='col-md-12 pt-2 pl-0 pr-0'>
        <div className="form-group">
          <input id='search_category' className='search_category form-control' placeholder='Buscar producto por sku o glosa' onKeyDown={handleSearchProduct} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({});


const wrapper = connect(mapStateToProps);
const component = wrapper(Search);

export default component;
