import React from "react";
import TitleButton from "../../components/TitleButtonEdit";
import InputTextAttribute from "../../components/InputTextAttribute";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalCheck from "../modal_check/ModalCheck";
import { connect } from 'react-redux'
// import './Logistic.css';

class Logistic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
      modalcheck: false,
      modalcheckData: [],
      sku: "",
    };
    this.inputEnabler = this.inputEnabler.bind(this);
    this.check_data = this.check_data.bind(this);
    this.changeStateModalChech = this.changeStateModalChech.bind(this);
    this.calcVolume = this.calcVolume.bind(this);
  }

  inputEnabler() {
    this.setState({ enabled: !this.state.enabled });
  }

  componentWillReceiveProps() {
    this.setState({ enabled: true });
  }

  check_data() {
    let array = [];
    let sku = document.getElementById("input_sku").value;

    document
      .querySelectorAll(
        ".logistic input[type=text], .logistic input[type=number]"
      )
      .forEach((select) => {
        let name = select.getAttribute("name");
        let value = select.value.toUpperCase().trim();
        if (value !== this.props[name].trim()) {
          let nextState = {};
          let nextAttributes = {};

          nextState["type"] = "product_change";
          nextState["id"] = sku;
          nextAttributes["sku"] = sku;
          nextAttributes["field_name"] = name;
          nextAttributes["field_detail"] = "";
          nextAttributes["old_value"] = this.props[name].trim();
          nextAttributes["new_value"] = value;
          nextAttributes["title"] = select.getAttribute("placeholder");
          nextAttributes["updated_by"] = localStorage.getItem("email");

          nextState["attributes"] = nextAttributes;
          array.push(nextState);
        }
      });

    if (array.length !== 0) {
      this.setState({ modalcheck: !this.state.modalcheck });
      this.setState({ modalcheckData: array });
      this.setState({ sku: sku });
    } else {
      toast.warn("No se ha realizado ningun cambio");
    }
    // this.send_data(array, sku);
  }

  changeStateModalChech() {
    this.setState({ modalcheck: !this.state.modalcheck });
  }

  calcVolume() {
    var m3 = 4000;
    var vol = document.getElementById("id_input_logistic_volume");
    var height = document.getElementById("id_input_logistic_height").value;
    var lenght = document.getElementById("id_input_logistic_lenght").value;
    var width = document.getElementById("id_input_logistic_width").value;
    vol.value = (height * lenght * width) / m3;
  }

  render() {
    
    let {logisticHeightRequired,logisticLenghtRequired,
         logisticWidthRequired,logisticVolumeRequired} = this.props.requiredAttribute
    return (
      <form className="logistic container Product-show-Logistic mb-4 was-validated">
        <ModalCheck
          modalcheck={this.state.modalcheck}
          idTable="modaltablecheckproducts"
          data={this.state.modalcheckData}
          changeStateModalChech={this.changeStateModalChech}
          sku={this.state.sku}
          id_btn_edit="btn_edit_logistic"
          getProductData={this.props.getProductData}
        />
        
          <TitleButton
            disabled={this.state.enabled}
            id_div_edit="div_edit_logistic"
            id_btn_edit="btn_edit_logistic"
            title="LOGÍSTICA - MEDIDAS DEL EMBALAJE UNITARIO"
            id_div_save="div_send_logistic"
            inputEnabler={this.inputEnabler}
            check_data={this.check_data}
            title_edit="Medidas"
            description="Datos para el embalaje y/u otros factores"
          />
        <div className="row">
          <div className="col-5">
            <InputTextAttribute
              measurement={true}
              title="Alto(cm)"
              name="logistic_height"
              required={logisticHeightRequired}
              id="id_input_logistic_height"
              disabled={this.state.enabled}
              value={this.props.logistic_height.replace(",", ".")}
              calcVolume={this.calcVolume}
              flexline_name="analisisproducto25"
              type="number"
              min="0"
              max="1000000"
            />
          </div>
          <div className="col-5">
            <InputTextAttribute
              measurement={true}
              title="Profundidad(cm)"
              name="logistic_lenght"
              required={logisticLenghtRequired}
              id="id_input_logistic_lenght"
              disabled={this.state.enabled}
              value={this.props.logistic_lenght.replace(",", ".")}
              calcVolume={this.calcVolume}
              flexline_name="analisisproducto26"
              type="number"
              min="0"
              max="1000000"
            />
          </div>
          <div className="col-5">
            <InputTextAttribute
              measurement={true}
              title="Ancho(cm)"
              name="logistic_width"
              required={logisticWidthRequired}
              id="id_input_logistic_width"
              disabled={this.state.enabled}
              value={this.props.logistic_width.replace(",", ".")}
              calcVolume={this.calcVolume}
              flexline_name="analisisproducto24"
              type="number"
              min="0"
              max="1000000"
            />
          </div>
          <div className="col-5">
            <InputTextAttribute
              measurement={true}
              title="Volumen(m3)"
              name="logistic_volume"
              required={logisticVolumeRequired}
              id="id_input_logistic_volume"
              disabled={this.state.enabled}
              value={this.props.logistic_volume.replace(",", ".")}
              flexline_name="volumen"
              type="number"
              min="0"
              max="1000000"
            />
          </div>
          <div className="col-5">
            <InputTextAttribute
              title="Factor"
              name="logistic_factor"
              id="id_input_logistic_factor"
              disabled={this.state.enabled}
              flexline_name="factoralt"
              value={this.props.logistic_factor}
              type="number"
              min="0"
              max="1000000"
            />
          </div>
          <div className="col-5">
            <InputTextAttribute
              title="Peso(kg)"
              name="logistic_weight"
              id="id_input_logistic_weight"
              disabled={this.state.enabled}
              flexline_name="peso"
              value={this.props.logistic_weight}
              type="number"
              min="0"
              max="1000000"
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  requiredAttribute: state.elementRequiredReducer.logistic
})


const mapDispatchToProps = (dispach) => ({
})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Logistic)

export default component;
