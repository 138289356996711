import React, { useEffect, useState } from "react";
import Title from './Title';
import ProductListTable from './ProductListTable';
import { getShowProductData } from '../../api/APIGet'
import es from '../../es.json'

// Product List
// 
// Render the Product List component
// Add products by sku
// Only add product if sku is valid
// Show sku and gloss of added products in a list
// 
// returns ProductList Component
function ProductList(props){

  // Product Collection state
  //
  // Store the first product collection
  // Then replicate the collection in the name
  const [productCollection, setProductCollection] = useState('')
  const [sku, setSku] = useState('')

  // Listen to product table rows change
  // Then get the added product collection
  // then replace the name with the collection name
  useEffect(() => {
    getProductCollection()
  }, [sku])

  // Listen to productCollection state
  // Set the product collection as the product name
  useEffect(()=>{
    console.log('ProductCollection Changed', productCollection)
    props.setConfigurableName(productCollection)
  },[productCollection])

  // Get the added product sku
  // Then ask the api for the collection value
  // Then stores the value in the ProductCollection state
  // returns [String] with the colection
  const getProductCollection = async () => {
    if(sku == ''){return setProductCollection('')}
    getShowProductData(sku).then(response => response.json())
                           .then(response => {
                              setProductCollection(response.data.attributes.collection)
                            })
  }

  // Renders the product list component
  return(
    <div className='row' id='recipe_form' name='recipe_form'>
      <div className='col-md-12'>
        <Title title={ es['ProductList'][props.name] }/>
      </div>
      <div className="col-md-12">
        <ProductListTable 
          name='product_list'
          tableRows= { props.productTableRows }
          sku={sku}
          setSku={setSku}
          setTableRows= { props.setProductTableRows }
          formKind= { props.formKind }
        />
      </div>
    </div>
  );

}

export default ProductList;
