import React, { useState, useEffect } from 'react';
import es from '../es.json';
import Title from "../components/Title";
import ProductChanges from "../product_changes/Index";
import ProductPrices from "../products_prices/Index";
import ProductPricesDetail from "../products_prices/Show";
import Products from "../products/Index";
import New from "../products/new/New";
import Login from "../users/Login";
import PriceList from "../products/show/pricelist/PriceList";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter, Route, Link } from "react-router-dom";
import { downloadProductExcel, uploadStock, TokenLogin } from '../api/APIPost'
import { exportPriceListExcel, pendingChangesQuantity } from '../api/APIGet'
import data from "../data.json";
import uuid from 'react-uuid'

// import './Navigation.css';

function NavList(props) {
  const [currentRole, setCurrentRole] = useState('')
  const [pendingChanges, setPendingChanges] = useState(0)

  useEffect(() => {
    setCurrentRole(localStorage.getItem('current_role'))
    retrievePendingChanges()
  },);

  // Login Access Use Efect
  // 
  // Check if user is logged in before every request
  // If not, redirect to login page
  //
  // @returns [null]
  useEffect(() => {
    LoginAccess()
  });
  

  // Check Bagde
  // First, check if the user is a supervisor or admin to display the badge with pending changes.
  // Second, check if there is more than zero pending changes to display the badge.
  // @returns [Boolean] True or false, depending if the badge need to be displayed.
  const checkBadge = () => {
    return ((checkRole('supervisor') || checkRole('admin')) && pendingChanges > 0)
  }

  // Retrieve Pending Changes
  // Calls the method to obtain from API the pending changes.
  // If the response is successful, sets as pendingChanges the response of API.
  // Sets pendingChanges in zero on error.
  // @returns [Null] changes the state of pendingChanges.
  const retrievePendingChanges = () => {
    pendingChangesQuantity().then( response => {
      if(response){
        const pendingChangesResponse = response.data.attributes.pending_changes
        setPendingChanges(pendingChangesResponse)
      }
      else{
        setPendingChanges(0)
      }
    })
  }

  // Check Role
  // 
  // Check if the user has the role to access the page
  // with the role data stored from the login
  // change if user can or can't view some elements in the page
  // 
  // @returns [null]
  const checkRole = (role) => {
    return role == currentRole
  }

  if (localStorage.getItem("access") === "true") {
    let roleClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? 'nav-item' : 'nav-item d-none'
    
    return (
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/product">
              { es["Navigation"]["products"] }
            </Link>
          </li>
          <li className={roleClass}>
            <Link className="nav-link" to="/productchanges"> { es["Navigation"]["products_changes"] }
              {
                checkBadge() ? 
                <span className="badge badge-danger badge-top ml-1"> { pendingChanges } </span>
                :
                null
              }
            </Link> 
          </li>
          <li className={roleClass}>
            <div className="nav-link" onClick={() => downloadProducts()}>
              { es["Navigation"]["export_products"] }
            </div>
          </li>
          <li className={roleClass}>
            <div className="nav-link" onClick={() => selectFile()}>
              { es["Navigation"]["upload_manual_stock"] }
            </div>
            <input id='upload_stock_excel'  name='upload_stock_excel' type='file' hidden={true} accept='.xlsx,.csv,.xls' onChange={(file) => uploadExcel(file)} />
          </li>
          <li className={roleClass}>
            <div className="nav-link" onClick={() => exportPriceList()}>
              { es["Navigation"]["export_price_lists"] }
            </div>
          </li>
          <li className={roleClass}>
            <Link className="nav-link"  to="/products_prices" >
              { es["Navigation"]["update_products_prices"] }
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            {localStorage.getItem("name") !== "null" && (
              <p className="navbar-text mb-0">{localStorage.getItem("name")}</p>
            )}
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/" onClick={props.logOutPim}>
              Salir
            </Link>
          </li>
        </ul>
      </div>
    );
  }
  return null;
}

// Download Products
// 
// Request to the api to download the products excel report
// The api then will schedule a job to send the generated report
// to the current user email
// 
// @returns [null]
function downloadProducts() {
  let email = localStorage.getItem('email')
  downloadProductExcel(email).then( response =>{
    if(response.status===204){
      toast.success(`Se enviara el documento al correo ${email} en cuanto termine de generarse.`);
    }else{
      toast.error('Error: no se ha podido generar el documento, Contacte a soporte');
    }
  })
}

// Export Price Lists
// 
// Request to the api to export the products price lists report
// The api then will schedule a job to send the generated report
// to the current user email
// @returns [null]
function exportPriceList() {
  let email = localStorage.getItem('email')
  exportPriceListExcel().then( response => {
    if(response.status == 200){
      toast.success(`Se enviara el documento al correo ${email} en cuanto termine de generarse.`);
    }else{
      toast.error('Error: no se ha podido generar el documento. Contacte a soporte');
    }
  })
}

// Select File
// 
// Upload stock excel, this function will be called when the user clicks on the upload stock button
// activate the input to upload a file
// 
// @returns [null]
function selectFile(){
  var element = document.getElementById('upload_stock_excel');
  if(element!==null){
    element.click();
  }
}

// Upload Excel
// 
// Upload stock excel to load PRODUCTO A PEDIDO Stock
// Manual stock upload, only the last upload excel will be loaded
// 
// @returns [null]
function uploadExcel() {
  var element = document.getElementById('upload_stock_excel');
  if(element!==null){
    var file = element.files[0];
    uploadStock(file)
    .then(res => {
      if (res.status===204) {
        setTimeout( () => {
          toast.success('El documento se subió exitosamente!');
        }, 2000);
      } else { toast.warn('Error interno, No se a podido subir su documento'); }
    })
    .catch( err => toast.error('Error interno, No se a podido subir su documento'));
  }
}

// All Routes
// 
// Map the routes that are available for navigation
// Map a url with a view component for rendering
// 
// @returns [ReactRouter Routes] with the available routes
function AllRoute() {
  if (localStorage.getItem("access") === "true") {
    return (
      <div className="product-views">
        <Route path="/productchanges" component={ProductChanges} />
        <Route path="/products_prices" component={ ProductPrices } />
        <Route path="/products_prices_detail/:id" component={ ProductPricesDetail } />
        <Route path="/product" component={Products} />
        <Route path="/pricelist/:id" component={PriceList} />
        <Route path="/new" component={New} />
        <Route path='/callback' component={Login} />
      </div>
    );
  }
  return null;
}

function LoginAccess() {
  console.log('Window Location', window.location.pathname)
  if (localStorage.getItem("access") === null && window.location.pathname !== "/callback") {
    window.location.href = `${data.login.url}/auth/login/?return_url=${window.location.origin.toString()}&callback_url=${window.location.origin.toString()}/callback&uuid=${uuid()}`
  }
}

// Render Login
// 
// Check if the user session is stored in the local storage
// If not, redirect to login page
// if stored, check if token is valid
// if not, redirect to login page
// 
// @returns [Login Component] if login is needed
function RenderLogin(){
  if (localStorage.getItem("access") === null) {
    return  <Login />
  }else{
    TokenLogin(localStorage.getItem('token')).then(response => {
      if (response && response.errors && !response.data) {
        localStorage.clear();
        LoginAccess()
      }
      })
  }
  return null
}

function Navigation() {
  useEffect(() => {
    const expiresAt = localStorage.getItem('expires_at');
    if (expiresAt !== null) {
      const expiresDate = new Date(expiresAt);
      const currentDate = new Date();
      if (currentDate > expiresDate) {
        logOutPim();
      }
    }
  }, []);

  const logOutPim = () => {
    localStorage.clear();
    window.location.href = `${data.login.url}/auth/clear_cookies?redirect_url=${window.location.origin.toString()}`;
  };

  return (
    <BrowserRouter>
      <ToastContainer />
      <div className="dimention-nav-bar" id="navbar">
        <nav className="col-12 navbar navbar-expand-lg navbar-light bg-light position-fixed">
          <Title />
          <NavList logOutPim={logOutPim} />
        </nav>
      </div>
        <Route
          exact
          path="/"
          component={props => {
            props.history.push(`/product`);
            return null;
          }}
        />
      <AllRoute />
      <RenderLogin />
    </BrowserRouter>
  );
}

export default Navigation;