import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Search from '../components/Search';
import Show from './show/Show';
import ProductList from './product_list/ProductList';
import { getProductsDataAll, getFamiliesData, getSubFamiliesData, getCategories, relatedCategories } from '../api/APIGet'
import { Route, Link, Routes } from 'react-router-dom';
import ReactLoading from 'react-loading';


const Index = ({ ...props }) => {
  
  const [data, setData] = useState("")
  const [selectedId, setSelectedId] = useState("")
  const [query, setQuery] = useState("")
  const [families, setFamilies] = useState([])
  const [subFamilies, setSubFamilies] = useState([])
  const [categories, setCategories] = useState([])
  const [loadProductList, setLoadProductList] = useState(true)
  const [loadProductShow, setLoadProductShow] = useState(false)
  const [currentRole, setCurrentRole] = useState('')

  useEffect(()=>{
    getProductsData('','','','','','','');
    setCurrentRole(localStorage.getItem('current_role'))
  },[])

  const getProductsData= (business, family, subfamily, category, product_type, state, query) => {
    setLoadProductList(true)
    getProductsDataAll(business, family, subfamily, category, product_type, state, query).then( response => {
      setLoadProductList(true)
      if(response){
        setData(response.data)
        setLoadProductList(false)
      }
    });
    
  }

  const getInitialFilters = () => {
    console.log('Get initial filters')
    getFamiliesData('').then( response => {
      if (response.data) {
        setFamilies(response.data.attributes)
      }
    }); 
    getSubFamiliesData('').then( response => { 
      if (response.data) {
        setSubFamilies(response.data.attributes)
      }
    });
    getCategories().then( response => {
      if(response.data){
        setCategories(response.data.attributes)
      }
    })
  }

  const filterCategoryData = (category, family, subfamily) => {
    if(!category && !family && !subfamily) { return getInitialFilters() }
    console.log('filterCategoryData')
    relatedCategories(category,family,subfamily).then(response => {
      setCategories(response.data.attributes.categories);
      setFamilies(response.data.attributes.families);
      setSubFamilies(response.data.attributes.subfamilies);  
    })
  }

  const checkRole = (role) => {
    return role == currentRole
  }

  let addProductClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? 'p-2' : 'p-2 d-none'

  let checkbox = Array.from(document.querySelectorAll('#checkbox'));
  checkbox.map((box) => {
    box.classList.add('d-none')
  });
  return(
    <div className='Product-index dimention-content'>
      <div id='product-new' className={addProductClass}>
        <Link to={'/new'} className='btn btn-primary btn-block'>
          Agregar nuevo producto
          <i className='fa fa-plus pl-2'></i>
        </Link>
      </div>
      <Search id='search' getInitialFilters={getInitialFilters} filterCategoryData={filterCategoryData} getProductsData={getProductsData} families={families} subfamilies={subFamilies} categories={categories}/>
      {loadProductList? 
        <div id='product-list' className='text-center d-flex mt-4 justify-content-center'>
          <ReactLoading delay={2000 /1000} color={'#544f92'} type='spokes'/>
        </div>
      :
        <ProductList data={data} id='product-list' />
      }
      <div className={`${loadProductList ? 'loading-state' : ''}`} id='product-detail'>
          <Route
          path='/product/:id'
          component={Show}
        />
      </div>
    </div>
  );

}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispach => ({
  changeRequiredState(typeSelected) {
    dispach({
      type: "CHANGE_REQUIRED",
      typeSelected
    });
  },
  loadProductData(typeSelected){
    dispach({
      type: "PRODUCT_LOADED",
      typeSelected
    })
  }
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Index);

export default component;