import React, { useState, useEffect } from 'react';
import TitleButtonPriceListGeneral from '../../components/TitleButtonPriceListGeneral';
import NetPriceInput from '../../components/NetPriceInput';
import { toast } from 'react-toastify';
import ModalCheck from '../modal_check/ModalCheck';
import { Link } from 'react-router-dom';
import { completePriceList } from '../../../api/APIPost'

const PriceListGeneral = ({
  sku,
  getProductData,
  prices,
  standarCost,
  cost,
  calculatePercentOfInput,
  setSaveNewData,
  saveNewData
}) => {

  const [enabled, setEnabled] = useState(true)
  const [modalCheckData, setModalCheckData] = useState([])
  const [modalCheck, setModalCheck] = useState(false)
  const [allPrices, setAllPrices] = useState(null)
  const [resetValueInput, setResetValue] = useState(false)
  const [save, setSave] = useState(false)
  const [currentRole, setCurrentRole] = useState("");

  useEffect(() => {
    setAllPrices(prices)
    setSave(true)
    setCurrentRole(localStorage.getItem('current_role'))
  }, [prices])

  useEffect(() => {
    if(!saveNewData) return
    setSaveNewData(false)
    setAllPrices(prices)
  }, [saveNewData])

  const generatePriceList = () => {
    completePriceList(sku).then(response => {
      if(response.status>199 && response.status<300){
        toast.success('Listado creado con éxito')
        getProductData(sku)
      } else {
        toast.error('Error al generar lista de precio')
      }
    })
  }

  const inputEnabler = () => {
    setEnabled(!enabled)
  }

  const resetValue = () => {
    setSaveNewData(true)
    setResetValue(true)
    setEnabled(!enabled)
  }

  const checkRole = (role) => {
    return role == currentRole
  }

  const changeStateModalCheck = () => {
    setModalCheck(!modalCheck)
  }

  const checkData = () => {
    let array = []
    let sku = document.getElementById('input_sku').value
    document.querySelectorAll('.listpricegeneral input[type=number]').forEach((select) => {
      let name = select.getAttribute('name');
      let value = select.value.trim();
      let oldValue = Math.round(select.getAttribute('oldValue'))
      let newValue = Math.round(value);

      if (newValue !== oldValue) {
        let nextState = {};
        let nextName = {};
        nextState['type'] = 'price_change';
        nextState['id'] = select.getAttribute('idListaPrecio');
        nextName['sku'] = sku;
        nextName['field_name'] = name;
        nextName['field_detail'] = select.getAttribute('field_detail');
        nextName['old_value'] = oldValue;
        nextName['new_value'] = newValue;
        nextName['margin'] = parseFloat(document.getElementById(`price_margin_${name.replace('Discount_', '')}`)?.innerText?.replace('%',''))
        nextName['title'] = select.getAttribute('placeholder') ? select.getAttribute('placeholder') : select.getAttribute('title');
        nextName['updated_by'] = localStorage.getItem('email');
        nextState['attributes'] = nextName
        array.push(nextState);
      }
    });
  
    if (array.length !== 0) {
      setModalCheckData(array)
      setModalCheck(!modalCheck)
    } else {
      toast.warn('No se ha realizado ningun cambio');
    }
  }

  const renderNetPriceInputs = () => {
    return (
      <>
        {allPrices && allPrices.length > 0 ?
          allPrices.map((data, i) => {
            let { name, idListaPrecio, value } = data
            return (
              <div className={`col-5 order-${i}`} key={i}>
                <NetPriceInput
                  name={name}
                  idListaPrecio={idListaPrecio}
                  value={parseInt(value)}
                  enabled={enabled}
                  standarCost={standarCost}
                  oldValue={value}
                  cost={cost}
                  calculatePercentOfInput = {calculatePercentOfInput}
                  required={true}
                  resetValueInput={resetValueInput}
                  setResetValue={setResetValue}
                  save={save}
                  setSave={setSave}
                  realTimeDiscount={0}
                />
              </div>
            )
          })
          :
          null
        }
      </>
    )
  }

  const renderContent = () => {
    cost = Math.round(cost);
    let costValidate = cost.length !== 0 && cost !== 0 && cost !== null && cost !== undefined
    let costZero = Math.round(parseInt(cost)*1.1)
    let withCostUp = Math.round(parseInt(standarCost)*1.1)
    let roleClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'

    return (
      <form className='container listpricegeneral Product-show-listPrice was-validated mb-3'>
        <ModalCheck
          modalcheck={modalCheck}
          idTable='modaltablecheckproducts'
          data={modalCheckData}
          changeStateModalChech={changeStateModalCheck}
          sku={sku}
          id_btn_edit='btn_edit_listprice' 
          getProductData={getProductData}
        />
        
          <TitleButtonPriceListGeneral
            disabled={enabled}
            id_div_edit='div_edit_listprice'
            id_btn_edit='btn_edit_listprice' 
            title='LISTADO DE PRECIOS'
            id_div_save='div_send_listprice'
            inputEnabler={inputEnabler}
            check_data={checkData}
            title_edit='Listado' 
            description={`El precio debe ser mayor a ${costValidate ? costZero : withCostUp }`}
            resetValue={resetValue}
          />
          <div className='row'>
          {renderNetPriceInputs()}
          <div className={`col-md-10 order-last ${roleClass}`}>
            <div className='row'>
              <div className='col-md-6'>
                <button
                  type='button'
                  className='btn btn-outline-primary'
                  onClick={generatePriceList}
                >
                  Generar precios faltantes
                </button>
              </div>
              <div className='col-md-6'>
                <Link
                  to={`/pricelist/${sku}`}
                  className='btn btn-primary btn-block'
                >
                  <span className='text-white mr-4'>Ver Listado de precios</span>
                  <i className='fa fa-database'/>
                </Link>
              </div> 
            </div>
          </div>
        </div>
      </form>
    )
  }

  return (
    <>
      {renderContent()}
    </>
  )
}

export default PriceListGeneral;
