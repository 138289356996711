import React, { useEffect } from "react";
import Required from "./Required.js";

const SelectAtribute = (props) => {
  useEffect(() => {
    const { value, id } = props;
    if (value !== undefined) {
      let generalSelect = document.getElementById(`${id}`);
      if (value !== "" && generalSelect.value === "") {
        let option = document.createElement("option");
        option.value = value;
        option.innerHTML = value;
        generalSelect.appendChild(option);
      }
      generalSelect.value = value;
    }
  }, [props.value, props.id]);

  let options = [];
  if (Array.isArray(props.options) && props.options.length > 0) { // Check if options array is not empty
    options = props.options.map((element, key) => {
      element = Array.isArray(element) ? element[0] : element;
      return element.trim().length !== 0 ? (
        <option value={element} key={key}>
          {element}
        </option>
      ) : null;
    });
  }

  return (
    <div className="row Product-show-attributes mb-3">
      <div className="col-md-4">
        <p
          className="mb-0"
          data-toggle="tooltip"
          data-placement="right"
          title={props.description}
        >
          {props.title}
          <Required required={props.required} />
        </p>
      </div>

      <div className="col-md-8">
        <select
          id={props.id}
          name={props.name}
          className="form-control form-control-sm"
          disabled={props.disabled}
          required={props.required}
          defaultValue={props.value}
          title={props.title}
          duplicate={props.duplicate}
        >
          <option value="" disabled={true}></option>
          {options}
        </select>
      </div>
      <div className="col-md-12">
        <small className="text-secondary">
          {`${props.flexline_name} ${
            props.duplicate !== undefined ? "| " + props.duplicate : ""
          }`}
        </small>
      </div>
    </div>
  );
};

export default SelectAtribute;