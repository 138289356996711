import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from '@rollbar/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './base_client.scss';
import Navigation from './navigation/Navigation';
import { Provider } from 'react-redux';
import store from './redux/store';

import * as serviceWorker from './serviceWorker';

const rollbarConfig = {
  accessToken: 'cbe6c9836ff64fde95520e7c1b5d41c3',
  environment: 'testenv',
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store} config={rollbarConfig}>
    <ErrorBoundary>
      <Navigation />
    </ErrorBoundary>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
