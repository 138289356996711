import React from 'react';
import TitleButton from '../../components/TitleButtonEdit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCheck from '../modal_check/ModalCheck';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' 

class RelatedProducts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
      modalcheck: false,
      modalcheckData: [],
      sku: '',
      related_products: []
    };
    this.inputEnabler = this.inputEnabler.bind(this);
    this.check_data = this.check_data.bind(this);
    this.changeStateModalChech = this.changeStateModalChech.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  inputEnabler(){
    this.setState({enabled: !this.state.enabled});
    if(!this.state.enabled && !this.state.modalcheckData.length){ this.setState({related_products: this.props.related_products}) }
  }

  componentWillReceiveProps(){
    this.setState({enabled: true});
    this.setState({related_products: this.props.related_products})
  }

  changeStateModalChech(){
    this.setState({modalcheck: !this.state.modalcheck});
  }

  check_data(){
    let array = [];
    let sku = document.getElementById('input_sku').value;
    let name = 'related_products';
    let value = this.state.related_products;
    let nextState = {};
    let nextAttributes = {};

    nextState['type'] = 'product_change';
    nextState['id'] = sku;
    nextAttributes['sku'] = sku;
    nextAttributes['field_name'] = name;
    nextAttributes['field_detail'] = '';
    nextAttributes['old_value'] = this.props.related_products;
    nextAttributes['new_value'] = value;
    nextAttributes['title'] = 'Productos relacionados';
    nextAttributes['updated_by'] = localStorage.getItem('email');

    nextState['attributes'] = nextAttributes;
    array.push(nextState);

    if(array.length!==0){
      this.setState({modalcheck: !this.state.modalcheck});
      this.setState({modalcheckData: array});
      this.setState({sku: sku});
    }else{
      toast.warn('No se ha realizado ningun cambio');
    }
  }

  handleChange(tags) {
    this.setState({related_products: tags})
  }

  render() {
    return(
      <div className='related_products container Product-show-related_products mb-4'>
        <ModalCheck modalcheck={this.state.modalcheck} idTable='modaltablecheckproducts' data={this.state.modalcheckData} 
        changeStateModalChech={this.changeStateModalChech} sku={this.state.sku} id_btn_edit='btn_edit_related_products' 
        getProductData={this.props.getProductData}/>
      
        <TitleButton disabled={this.state.enabled} id_div_edit='div_edit_related_products' id_btn_edit='btn_edit_related_products' 
        title='Productos relacionados' id_div_save='div_send_related_products' inputEnabler={this.inputEnabler} 
        check_data={this.check_data}
        title_edit='Sku'
        description='Ingresa el SKU, presiona [ENTER] para agregarlo al listado' />
        <div className='row'>  
          <div className='col-md-12'>
            <TagsInput inputProps={{placeholder:'Agrega los SKU relacionados. Ingresa el SKU, presiona [ENTER] para agregarlo al listado'}} value={this.state.related_products}
             onChange={this.handleChange} disabled={this.state.enabled} removeKeys={[7]}/>
          </div>
           
        </div>
      </div>
    );
  }
}
export default RelatedProducts;
