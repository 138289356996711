import React, { useState, useEffect } from 'react';

const TitleButtonPriceListGeneral = ({
    disabled,
    id_div_edit,
    id_btn_edit,
    title,
    id_div_save,
    inputEnabler,
    check_data,
    title_edit,
    description,
    resetValue
}) => {

    const [currentRole, setCurrentRole] = useState("");

    useEffect(() => {
      setCurrentRole(localStorage.getItem('current_role'))
    }, [])

    const checkRole = (role) => {
      return role == currentRole
    }

    let roleClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'

    return (
        <div className='row' name='form_change_name'>
        <div className='col-md-8 mb-4'>
          <hr/>
          <h5 className='m-0'>{title}</h5>
          {
            Array.isArray(description) ? description.map((text,i) => {
              return(
              <small className='text-secondary' key={i}>{text}</small>
              );
            }) : <small className='text-secondary'>{description}</small>
          }
        </div>
        {disabled ?
            <div className={`col-md-4 ${roleClass}`} id={id_div_edit}>
                <button className='btn btn-primary btn-block text-white mt-3' id={id_btn_edit} type='button' onClick={inputEnabler}>
                    Editar {title_edit}
                </button>
            </div>
        :
            <div className={`col-md-2 ${roleClass}`} id={id_div_edit}>
                <button className='btn btn-primary btn-block text-white mt-3' id={id_btn_edit} type='button' onClick={resetValue}>
                    Deshacer
                </button>
            </div>
        }
        <div id={id_div_save} className={`col-md-2 ${roleClass}`} hidden={disabled}>
          <button id='title_btn_send_name' className='btn btn-primary btn-block text-white mt-3' type='button' onClick={check_data}>
            Guardar
          </button>
        </div>
      </div>
    )

}
export default TitleButtonPriceListGeneral;