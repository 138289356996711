import React, { useEffect, useState } from 'react';
import NetPriceInput from '../../components/NetPriceInput';
import DiscountInput from '../../components/DiscountInput';

function RenderNetPriceInputs({
  priceList,
  enabled,
  standarCost,
  cost,
  resetValueInput,
  setResetValue,
  save,
  setSave,
}) {
  const [realTimeDiscounts, setRealTimeDiscounts] = useState({});

  useEffect(() => {
    // Using if statement instead of ternary operator for clearer side-effects logic
    if (priceList && Object.keys(priceList).length > 0) {
      priceList.attributes.prices.forEach((data) => {
        let { name, discount } = data;
        setRealTimeDiscounts(current => ({
          ...current,
          [name]: parseInt(discount).toString().replace('-', '')
        }));
      });
    }
  }, [priceList]); // Added priceList as a dependency

  const setRealTimeDiscount = (id, discount) => {
    setRealTimeDiscounts(current => ({
      ...current,
      [id]: discount,
    }));
  };

  return (
    <>
      {priceList && Object.keys(priceList).length > 0 ? (
        priceList.attributes.prices.map((data, i) => {
          let { name, idListaPrecio, value } = data;
          return (
            <div className="col-6" key={i}>
              <NetPriceInput
                name={name}
                idListaPrecio={idListaPrecio}
                value={parseInt(value)}
                enabled={enabled}
                standarCost={standarCost}
                oldValue={value}
                cost={cost}
                resetValueInput={resetValueInput}
                setResetValue={setResetValue}
                save={save}
                setSave={setSave}
                realTimeDiscount={realTimeDiscounts[name] || 0}
              />
              <DiscountInput
                enabled={enabled}
                data={data}
                resetValueInput={resetValueInput}
                setResetValue={setResetValue}
                setRealTimeDiscount={setRealTimeDiscount}
              />
            </div>
          );
        })
      ) : null}
    </>
  );
}

export default RenderNetPriceInputs;
