import React from 'react';

class TextAreaAttribute extends React.Component{
    constructor(params) {
      super();
    }

    componentDidMount(){
      let {id, value} = this.props
      document.getElementById(id).value = value
    }

    render(){
      let {id, title, name} = this.props
      return(
        <div className='col-12'>
          <div className='row form-group'>
            <label className='col-md-4 p-0' htmlFor={id}>{title}</label>
            <textarea className='form-control col-md-12' name={name} id={id} rows='6' placeholder={title}/>
          </div>
        </div>
      )
    }
}
export default TextAreaAttribute