import React, { useState, useEffect } from 'react';
import { TokenLogin } from '../api/APIPost';

function Login(props){
  
  // Callback for the login button
  // 
  // Read the params from the url
  // @params user_token, auth token from api
  // @params return_url, url to redirect to after login
  // Connects to the api with the token to retrieve user data
  // If the user is successfully logged in, redirect to the return_url
  // 
  // @returns [null]
  useEffect(() => {
    let params = window.location.href.split('?')
    if (params.length > 1) {
      let params_array_values = params[1].split('&')
      let user_token = params_array_values[0].split('=')[1];
      let redirect_url = params_array_values[1].split('=')[1];
      TokenLogin(user_token).then(response => {
        
        if (response.data.attributes) {
          window.location.href = redirect_url
        }})
    }})

  return (
   <>
   </>
  );
}

export default Login;
