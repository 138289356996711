import React from 'react';

function Required(props){
  if(props.required){
    return(
      <span className='text-danger'>{' *'}</span>
    );
  }
  return(null);
}

export default Required;