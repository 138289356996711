import React from 'react';
// import './UpVideo.css';

class UpVideo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: true};
    this.changeVideo = this.changeVideo.bind(this)
  }

  getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
        return match[2];
    } else {
        return 'error';
    }
  }

  changeVideo = (e) => {
    if (e.key === 'Enter') {
      let inputIdVideo = document.getElementById(`${this.props.id}`).value
      var videoId = this.getId(`https://www.youtube.com/watch?v=${inputIdVideo}`);
      document.getElementById(`${this.props.id_vid}`).src = `https://www.youtube.com/embed/${videoId}`
    }
  }

  componentDidUpdate(){
    let { id, value, id_vid } = this.props
    document.getElementById(id).value = value===undefined? '' : value
    if(value !== ''){
      var videoId = this.getId(`https://www.youtube.com/watch?v=${value}`);
      document.getElementById(id_vid).src = value===undefined? '' : `https://www.youtube.com/embed/${videoId}`
    }else{
      document.getElementById(id_vid).src = '';
    }
  }
  
  render() {
    return(
      <div className='col-12 mt-3'>
        <div className='row'>
          <div className='col-md-6'>
            <p className='mb-0'>{this.props.title}</p>
            <small className='text-secondary'>
              {this.props.flexline_name}
            </small>
            <small className='text-secondary'>
              <p className='m-0'>{this.props.sub_title}</p>
              <small>{this.props.example}</small>
            </small>
            <div className='input-group mb-3'>
              <input id={this.props.id} name={this.props.name} className='form-control' 
              disabled={this.props.disabled} onKeyDown={this.changeVideo} placeholder={this.props.title}/>
            </div>
          </div>
          <div className='col-md-6'>
            <iframe id={this.props.id_vid} src='' title='Video Youtube'/>
          </div> 
        </div>
      </div>
    );
  }
}
export default UpVideo;
