import React, { useState, useEffect } from 'react';
import TitleOnlySave from '../../components/TitleOnlySave';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { toast } from 'react-toastify';
import { createProduct, createCustomProduct } from '../../../api/APIPost';
import Title from '../../../components/Title';
// import './ModalCheck.css';

function SaveProduct(props){
  if(props.elementNCheck){
    return (
      <TitleOnlySave title={''} check_data={props.check_data}/>
    );
  } else {
    return (
      <Title title='Complete los campos requeridos' />
    );
  }
}

const ModalCheck = (props) => {
  const [open, setOpen] = useState(false);
  const check_data = () => {
    props.changeStateModalChech();
    if(props.selectedKind === 'configurable'){
      createCustomProduct(props.data)
        .then(response => {
          toast.success('El producto se agregó con éxito');
          setTimeout(() => {
            window.location.assign('/product/' + props.sku);
          }, 1000);
        })
        .catch(err => toast.error('No se ha podido mandar los datos, vuelva a intentarlo'));
    } else {
      createProduct(props.data)
        .then(response => {
          toast.success('El producto se agregó con éxito');
          setTimeout(() => {
            window.location.assign('/product/' + props.sku);
          }, 1000);
        })
        .catch(err => toast.error('No se ha podido mandar los datos, vuelva a intentarlo'));
    }
  }

  // useEffect(() => {
  //   if(props.modalcheck && (props.allElementNotChecked === 'Campos' || props.allElementNotChecked === 0) && !open) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [props.modalcheck, , open]);

  return (
    <div>
      <Popup
        open={props.modalcheck && (props.allElementNotChecked === 'Campos' || props.allElementNotChecked === 0)}
        closeOnDocumentClick={false}
      >
        <div className='container'>
          <SaveProduct elementNCheck={props.allElementNotChecked === 'Campos'} check_data={check_data} sku={props.sku} selectedKind={props.selectedKind}/>
          <div className='row mt-3'>
            <div className='col-12 text-center'>
              <h4 className={props.allElementNotChecked === 'Campos' ? 'text-success' : 'text-danger'}>
                {props.allElementNotChecked === 'Campos' ? 'Puede guardar sin problema' : `${props.allElementNotChecked} campos faltan por completar`}
              </h4>
              <h6>Compruebe los datos antes de guardar</h6>
            </div>
            <div className='col-3'>
              <button className='btn btn-danger btn-block' onClick={props.changeStateModalChech}>Salir</button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default ModalCheck;
