import React from 'react';
import UpImage from '../../components/UpImage';
import Required from '../../components/Required';
// import './ItemImage.css';

class ItemImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
      new_src: []
    };
  }
  

  componentWillReceiveProps(props){
    var all_photos = []
    props.photos.forEach((element) => {
      if(element.usage === 'main'){
        all_photos.push(element.url)
      }
    });
    this.setState({
      new_src: all_photos
    });
  }

  render() {
    let {photos, usage, getProductData, sku, disabled_btn_edit, min, disabled_select_img, title, required, descript, max, id_img, id, name} = this.props
    var new_photos = photos.map((element, i) => {
      let {photo_id, url, title, description} = element
      if(usage === element.usage){
        return(
          <UpImage getProductData={getProductData} sku={sku} id_img={'id_img_'+photo_id} 
          id={photo_id} name={'name_fot_'+photo_id} usage={usage}
          disabled_btn_edit={disabled_btn_edit} src={url} key={i} input_name={'input_name_'+photo_id} hidden={false}
          disabled_btn_delete={photos.length<=min} disabled_select_img={disabled_select_img} title={title} description={description}/>
        );
      }else{
        return null;
      }
    });

    new_photos = new_photos.filter(function (el) {
      return el != null;
    });

    return(
      <div className='row'>
        <div className='col-md-6 mt-3'>
          <p className='mb-0'>
            {title}
            <Required required={required} />
          </p>
          <p className='small text-secondary'>{descript}</p>
        </div>
        <div className='col-md-6 row justify-content-start'>
          {new_photos.slice(0,max)}
          <UpImage getProductData={getProductData} sku={sku} id_img={id_img} 
          id={id} name={name} disabled_btn_edit={disabled_btn_edit} src={null} 
          input_name={null} usage={usage} hidden={new_photos.length>=max} disabled_btn_delete={true} disabled_select_img={true}/>
        </div>
      </div>
    );
  }
}
export default ItemImage;
