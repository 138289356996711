import React, { useState, useEffect }   from 'react';
import InputTextAttribute from '../custom_product/InputTextAttribute';
import Title from '../../components/Title';
import es from "../../../es.json"
import './Sheet.css';
import TitleButton from '../../components/TitleButtonEdit';
import ModalCheck from '../../show/modal_check/ModalCheck';
import { connect } from 'react-redux';

// Render The Product Sheet but with checkbox instead of inputs or selects
function Sheet(props){
  const [editEnabled, setEditEnabled] = useState(true)
  const [modalEnabled, setModalEnabled] = useState(false)
  const [state, setState] = useState({})
  const [originalState, setOriginalState] = useState({})

  // Check Data
  // 
  // Check if the current data is different than the original load data
  // if different raise modal
  const check_data = () => {
    let _ = require('lodash')
    if(_.isEqual(originalState, state)){
    }else{
      toggleModal()
    }
  }

  // UseEffect
  // mapPropToState
  // Map the props to the state every time the props change
  useEffect(() => {
    mapPropsToState()
  }, [props])

  // Map props to state
  // 
  // Map the props value to a current state useState
  const mapPropsToState = () => {
    let currentState = {}
    currentState['height'] = props.attributes.includes('height')
    currentState['lenght'] = props.attributes.includes('lenght')
    currentState['width'] = props.attributes.includes('width')
    currentState['color'] = props.attributes.includes('color')
    currentState['materiality'] = props.attributes.includes('materiality')
    currentState['brand'] = props.attributes.includes('brand')
    currentState['space_type'] = props.attributes.includes('space_type')
    currentState['collection'] = props.attributes.includes('collection')
    currentState['size'] = props.attributes.includes('size')
    currentState['package_type'] = props.attributes.includes('package_type')
    setState(currentState)
    setOriginalState({...currentState})
  }

  // Check Role
  // 
  // Check if the props current role is the same as the function one
  const checkRole = (role) => {
    return role == props.currentRole
  }

  // ToggleEdit
  // 
  // Toggle the edit status, enable or disable edit
  // of the custom attributes
  const toggleEdit = () => {
    setEditEnabled(!editEnabled)
    if(!editEnabled){
      mapPropsToState()
    }
  }

  // Toggle Check Box
  // 
  // Toggle the selected checkbox, checked unchecked
  const toggleCheckbox = (event) => {
    if(!editEnabled){
      let currentState = state[event.target.name]
      let newState = state
      newState = {...state, [event.target.name]: !currentState }
      setState(newState)
    }
  }

  // Get True Keys
  // 
  // Get the true keys from the object given
  const getTrueKeys = (list) => {
    let trueKeys = []
    Object.keys(list).forEach((k) => {
      if(list[k]){
        trueKeys.push(k)
      }
    })
    return trueKeys;
  }

  // Set Custom Attributes Data
  // 
  // Wrapper of the changes to submit to the api
  const setCustomAttributesData = () => {
    let array = []
    let nextState = {}
    let nextAttributes = {}
    let {sku} = props
    if(state && originalState){
      let custom_attributes = {
        custom_attributes_old: getTrueKeys(originalState), 
        custom_attributes_new: getTrueKeys(state)
      }
      nextState['type'] = 'product_change'
      nextState['id'] = sku
      nextAttributes['sku'] = sku
      nextAttributes['field_name'] = 'configurable_attributes'
      nextAttributes['field_detail'] = ''
      nextAttributes['old_value'] = custom_attributes[`custom_attributes_old`]
      nextAttributes['new_value'] = custom_attributes[`custom_attributes_new`]
      nextAttributes['title'] = es['Common']['attributes'].toUpperCase()
      nextAttributes['updated_by'] = localStorage.getItem('email');
      nextState['attributes'] = nextAttributes
      array.push(nextState)
    }
    
    return array
  }

  // Toggle Modal
  // 
  // Open and close the modal
  const toggleModal= () => {
    setModalEnabled(!modalEnabled)
  }

  let roleClass = (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'

  return(
    <div className='row'>
      <div className='col-md-12 mt-2'>
      <ModalCheck modalcheck={modalEnabled} idTable='modaltablecheckrecipe' data={ setCustomAttributesData() } 
                  changeStateModalChech={ toggleModal } sku={ props.sku } id_btn_edit='btn_edit_attribute_configuration'
                  getProductData={props.productLoad}/>
      <TitleButton disabled={editEnabled} id_div_edit='div_edit_attribute_configuration' id_btn_edit='btn_edit_attribute_configuration' 
            title={es['Common']['attributes'].toUpperCase()} id_div_save='div_send_attribute_configuration' 
            inputEnabler={ toggleEdit } roleVerification={ roleClass }
            table='attribute_configuration' check_data={check_data} title_edit='Atributos'/>
      </div>
      <div className='col-md-5'>
      
        <InputTextAttribute 
        measurement={true}
        title= { es['SkusTable']['height'] }
        name='height'
        id='id_input_height'
        type='checkbox'
        disabled={editEnabled}
        checked = {state['height']}
        onClick={toggleCheckbox}
        min='0'/>
      </div>
      <div className='col-md-7'>
        <InputTextAttribute 
        measurement={true}
        title={ es['SkusTable']['lenght'] }
        name='lenght'
        id='id_input_lenght'
        type='checkbox'
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['lenght']}
        min='0'/>
      </div>
      <div className='col-md-5'>
        <InputTextAttribute 
        measurement={true}
        title={ es['SkusTable']['width'] }
        name='width'
        id='id_input_width'
        type='checkbox'
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['width']}
        min='0'/>
      </div> 
      <div className='col-md-7'>
        <InputTextAttribute title={ es['SkusTable']['color'] }
        name='color'
        id='id_input_color'
        calculatePercentOfInput = {props.calculatePercentOfInput}
        type='checkbox'
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['color']}
        notRequired={true}/>
      </div> 

      <div className='col-md-5'>
        <InputTextAttribute title={ es['SkusTable']['materiality'] }
        name='materiality'
        id='id_input_materiality' 
        type='checkbox'
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['materiality']}/>
      </div>

      <div className='col-md-7'>
        <InputTextAttribute  
        id='id_input_brand' 
        title={ es['SkusTable']['brand'] } 
        name='brand'
        type='checkbox'
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['brand']}
        flexline_name = ''/>
      </div>

      <div className='col-md-5'>
        <InputTextAttribute title={ es['SkusTable']['space_type'] }
        name='space_type'
        id='id_input_space_type' 
        type='checkbox'
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['space_type']}/>
      </div>

      <div className='col-md-7'>
        <InputTextAttribute title={ es['SkusTable']['collection'] }
        name='collection'
        id='id_input_collection' 
        type='checkbox'
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['collection']}/>
      </div> 

      <div className='col-md-5'>
        <InputTextAttribute  
        id='id_input_size' 
        title={ es['SkusTable']['size'] }
        type='checkbox'
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['size']}
        name='size'/>
      </div>

      <div className='col-md-7'>
        <InputTextAttribute 
        id='id_input_package_type'
        type="checkbox"
        disabled={editEnabled}
        onClick={toggleCheckbox}
        checked = {state['package_type']}
        title={ es['SkusTable']['package_type'] }
        name='package_type'/>
      </div>
    </div>
  );
}

export default Sheet;
