import React, { useState, useEffect } from "react";
import {Tooltip as ReactTooltip} from 'react-tooltip';
import Published from "../../show/published/Published";
import { getStockProjection } from '../../../api/APIGet'
import Modal from "react-bootstrap/Modal";
import es from "../../../es.json"

const Title = ({sku, barPercentage, status, publish_segment, action, type}) => {

  const [projectionData, setProjectionData] = useState([])
  const [projectionDataModal, setProjectionDataModal] = useState(false)

  useEffect(() => {
    if(projectionData.length == 0) retrieveStockProjection()
  }, [])

  // Retrieve Stock Projection
  // Calls the method to obtain from API the stock projection.
  // If the response is successful, sets as projectionData the response of API.
  // Sets projectionData in empty array on error.
  // @returns [Null] changes the state of projectionData.
  const retrieveStockProjection = () => {
    if(projectionData.length != 0) return

    getStockProjection(sku).then( response => {
      if(response){
        setProjectionData(response.data)
      }
    })
  }

  const hideStockProjectionModal = () => {
    setProjectionDataModal(false)
  }

  const getAccumulated = (stock_data) => {
    console.log(stock_data)
    let sum = stock_data.reduce((acc, curr) => {
                if (typeof curr === "number") {
                  return acc + curr;
                } else {
                  return acc;
                }
              }, 0)
    return sum
  }

  return (
    <div className="col-12 product-show-name" name='form_change_name'>
      <div className="container">
        <div className='row mt-5'>
          <div className='col-md-6'>
            <h4 id='name_product_h2'>{sku}</h4>
            { action === 'create' ? '' :
              <Published
              barPercentage= { barPercentage }
              status= { status }
              publish_segment= { publish_segment }
            />
            }
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12 text-right'>
                <label>{ es['Components']['Name']['completed_file'] }</label>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8 offset-4'>
                <div className='progress dashboard-persent text-center'>
                  <div id='percent-bar' className='progress-bar bg-progress' role='progressbar' aria-valuemin='0' aria-valuemax='100'></div>
                  <label id='number-percent-bar' className='pr-3 justify-content-center d-flex position-absolute w-100 text-progressbar font-weight-bold'></label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 text-right'>
                <label id='number-incomplete-input' className='mt-1' data-tip='' />
                <ReactTooltip place="top" type="dark" effect="float"/>
              </div>
            </div>
            <div className='row'>
              <div className="d-none col-md-8">
                <label id='show-sku' className='text-primary'></label>
              </div>
            </div>
          </div>
        </div>
        { projectionData.length != 0   ?
            <div>
              <div>
                <Modal show={ projectionDataModal } onHide={ hideStockProjectionModal } size='lg'>
                  <Modal.Header className="d-block">
                    <div className="row">
                      <div className="col-10">
                        <h4> { es['StockProjections']['titleModal'] } </h4>
                      </div>
                      <div className="col-2 text-right">
                        <button type='button'
                            className={`btn btn-primary`}
                            onClick={ hideStockProjectionModal }>
                          { es['Common']['close'] }
                        </button>
                      </div>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th className='col-md-2'> Cantidad Actual </th>
                        <th className='col-md-2'> Stock Proyección </th>
                        <th className='col-md-2'> Stock Salida </th>
                        <th className='col-md-2'> Sugerencia </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (projectionData).map((projection,i) => {
                          return(
                            <tr id={ `${projection['attributes']['sku']}_${projection['id']}` } key={i}>
                              <td> { projection['attributes']['stock'] } </td>
                              <td> { getAccumulated(projection['attributes']['projection']) } </td>
                              <td> { getAccumulated(projection['attributes']['sold']) } </td>
                              <td> { getAccumulated(projection['attributes']['estimation']) } </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  </Modal.Body>
                </Modal>
              </div>
              <div className='row justify-content-end mb-3'>
                <div className='col-4'>
                  <button type='button'
                          className={`btn btn-primary btn-block`}
                          onClick={(e) => setProjectionDataModal(true)}>
                    { es['Common']['stock_projection_movements'] }
                  </button>
                </div>
              </div>
            </div>
          :
            null
        }
      </div>
    </div>
  );
}

export default Title;