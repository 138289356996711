import React from 'react';
import ProductList from '../product_list/ProductList'
import { Link } from 'react-router-dom';
import { getPendingChanges } from '../../api/APIGet';
import { massiveApproveChanges } from '../../api/APIPost';
import { toast } from 'react-toastify';
import Pagination from "@material-ui/lab/Pagination";
import './PendingChange.css'
// import { data } from './data.json'
// import './Index.css';

class Index extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedId: null,
      error: false,
      totalPages: 10,
      current_page: 1,
      role: localStorage.getItem('current_role')
    }
    this.getProductsData = this.getProductsData.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount(){
    this.getProductsData(this.state.current_page, this.state.role);
  }

  handleChange = (event, value) => {
    this.setState({current_page: value})
    this.getProductsData(value, this.state.role);
  }

  submitRole = (event) => {
    this.setState({ role: event.target.value })
    this.getProductsData(this.state.current_page, event.target.value);
  }

  submitMassiveApprove = (data) => {
    if(data.length === 0){
      return toast.warn('No se ha seleccionado nada')
    }
    massiveApproveChanges(data).then( response => {
      if(response.status===204){
        this.getProductsData(this.state.current_page, this.state.role);
        toast.success('Cambios realizados');
      }else if(response.status===403){
        toast.error('No tienes permisos para realizar esta acción');
      }else{
        toast.error('No se ha podido cambiar los datos, vuelva a intentarlo');
      }
    });
  }

  getChangesChecked = () => {
    let changes = Array.from(document.querySelectorAll('input[type="checkbox"]'));
    let checked = []
    changes.map((change) => {
      if(change.checked){
        checked.push(change.id)
      }
    });
    return checked
  }

  checkAll(){
    let checkbox = Array.from(document.querySelectorAll('input[type="checkbox"]'));
    checkbox.map((box) => {
      box.checked = true
    });
    document.querySelector('#disable').classList.remove('btn-hidden')
    document.querySelector('#select').classList.add('btn-hidden')
  }

  uncheckAll(){
    let checkbox = Array.from(document.querySelectorAll('input[type="checkbox"]'));
    checkbox.map((box) => {
      box.checked = false
    });
    document.querySelector('#disable').classList.add('btn-hidden')
    document.querySelector('#select').classList.remove('btn-hidden')
  }

  getProductsData(page, role){
    getPendingChanges(page, role).then( response => {
      if(response!==undefined){
        this.setState({ data: response.data})
        if(response.meta!==undefined){
          
          this.setState({
            current_page: page,
            totalPages: response.meta.max_pages,
          })
        }
      }else{
        this.setState({ error: true })
      }
    });
  }

  render() {

    if(this.state.data===undefined || this.state.data===null){
      if(this.state.error){
        return(
          <div className='row'>
            <div className='col-md-12 mt-5 text-center'>
              <h1 className='text-primary'>Acceso no permitido, Pregunta a un supervisor</h1>
            </div>
          </div>
        )
      }else{
        return <h2 className='col-12'>Cargando...</h2>;
      }
    }

    return(
      <div className='row'>
        <hr className='col-md-12'/>
        <div className='col-md-4'>
          <h3 className='ml-2'>CAMBIOS PENDIENTES</h3>
        </div>
        <div className='col-md-4'>
          <Pagination count={this.state.totalPages} page={this.state.current_page} onChange={this.handleChange} color="primary" className="justify-content-center"/>
        </div>
        <div className='col-md-4 text-right'>
          <Link className='btn btn-primary' to='/productchanges/all'>Cambios realizados</Link>
        </div>
        <div className='col-md-4 mt-3'>
          <select id='user_role'
                  className='form-control form-control-sm'
                  onClick={event => this.submitRole(event)}
          >
            <option defaultValue={this.state.role == 'admin'} value='admin'> ADMINISTRADOR</option>
            <option defaultValue={this.state.role == 'supervisor'} value='supervisor'> SUPERVISOR</option>
          </select>
        </div>
        <div className='col-md-12 mt-3'>
          <ProductList data={this.state.data} getProductsData={this.getProductsData} pending_change={true}/>
        </div>
        <div className='col-md-6'>
          <button id='select' className='btn btn-primary' type='button' onClick={ () => { this.checkAll()} }>
            {'Seleccionar todo'}
          </button>
          <button id='disable' className='btn btn-primary btn-hidden' type='button' onClick={ () => { this.uncheckAll()} }>
            {'Deseleccionar todo'}
          </button>
        </div>
        <div className='col-md-6 text-right'>
          <button className='btn btn-primary' type='button' onClick={ () => { this.submitMassiveApprove(this.getChangesChecked())} }>
            {'Aprobación masiva'}
          </button>
        </div>
      </div>
    );
  }
}

export default Index;