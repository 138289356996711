import React from 'react';
import InputTextAttributeFull from '../../components/InputTextAttributeFull';
import Title from '../../components/Title';

// import './Attributes.css';

class Attributes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: false};
  }

  render() {
    return(
      <div className='row Product-show-attributes'>
        <div className='col-md-12 mt-3'>
          <Title title='NOMBRE'/>
        </div>

        <div className='col-md-12'>
          <InputTextAttributeFull title='Nombre' name='name' id='name' disabled={this.state.enabled} 
          selectedKindState={ this.props.selectedKindState }
          configurableName={this.props.configurableName}
          maxlength='160'
          name_second = 'gloss'
          id_second = 'id_gloss'
          title_second = 'Glosa'
          maxlength_second = '100'
          required={true}
          description_name='Detalle del producto'
          description_gloss='Detalle que aparece en la facturación y Notas de venta'
          description_print='Detalle que aparece al imprimir'
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          flexline_gloss = 'glosa'
          flexline_name = ''
          />
        </div>
      </div>
    );
  }
}
export default Attributes;