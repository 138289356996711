import React, { useState, useEffect } from 'react';
import es from '../../es.json';
import ProductItem from './ProductItem'


const ProductList = ({ ...props }) => {

  const [currentRole, setCurrentRole] = useState('')

  useEffect(() => {
    setCurrentRole(localStorage.getItem('current_role'))
  },);
  
  const checkRole = (role) => {
    return role == currentRole
  }

  let roleClass =  (checkRole('basic') || checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? 'mt-2 mx-2 border-top' : 'mt-2 mx-2 border-top d-none'

  if(props.data.length === 0) {
    return(
      <h2 className='col-12 mt-4'>{ es['Common']['no_result'] }</h2>
    )
  } 
  
  let card_todos = props.data.map((product, i) => {
    return(
      <ProductItem product={ product } key={i} />
    )
  });

  return(
    <div id={props.id} className={roleClass}>
      {card_todos}
    </div>
  );
}

export default ProductList;
