import React from 'react';
import InputTextAttribute from '../../components/InputTextAttribute';
import Title from '../../components/Title';
import SkuAtomatic from '../../components/SkuAutomatic';
import es from '../../../es.json'
// import './Sku.css';

class Sku extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: true};
    this.inputEnabler = this.inputEnabler.bind(this);
  }

  inputEnabler(){
    this.setState({enabled: !this.state.enabled});
  }
  
  render() {
    return(
      <div className='row'>
        <div className='col-md-12'>
          <Title title='SKU'/>
        </div>

        <div className='col-md-6'>
          <InputTextAttribute title='SKU' name='sku' id='input_sku'
          description= { es['Sku']['description'] }
          required={true}
          type='text'
          maxlength='20'
          skuAuto = {`${this.state.enabled}`}
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          selectedKindState = { this.props.selectedKindState }/>
        </div>
        <div className='col-md-6 mt-1'>
          <SkuAtomatic 
            id_input='input_sku' 
            id='check_sku_id'
            title= { es['Sku']['title'] }
            inputEnabler = {this.inputEnabler}
            changeSkuToAuto = {this.props.changeSkuToAuto}/>
        </div>    
          
      </div>
    );
  }
}
export default Sku;
