import React from 'react';
import Attributes from './attributes/Attributes';
import Sheet from './sheet/Sheet';
import CheckboxSheet from './checkbox_sheet/CheckboxSheet';
import Title from '../components/name/Name';
import Sku from './sku/Sku';
import Name from './name/Name';
import Price from './price/Price';
import Logistic from './logistic/Logistic';
// import PriceList from './pricelist/PriceList';
import 'react-toastify/dist/ReactToastify.css';
import { barcodeAvailable, skuAvailable } from '../../api/APIGet';
import es from '../../es.json';
import ModalCheck from './modal_check/ModalCheck';
import { toast } from 'react-toastify';
import latinize from 'latinize';
import ProductSelector from '../components/ProductSelector';
import Recipe from '../components/Recipe';
import ProductList from '../components/ProductList';
import { throwStatement } from '@babel/types';

var minstring = 2;
var maxstring = 3;
class New extends React.Component {
  constructor() {
    super();

    this.state = {
      data: '',
      cost: 0,
      modalcheck: false,
      modalcheckData: [],
      sku: '',
      allElementNotChecked: 0,
      html_text_descript: '',
      productType: '',
      selectedKind: 'simple',
      tableRowsPrincipal: [],
      tableRowsAlternative: [],
      productTableRows: [],
      configurableName: '',
    };
    this.check_data = this.check_data.bind(this);
    this.changeStateModalChech =
      this.changeStateModalChech.bind(this);
    this.getDesc = this.getDesc.bind(this);
    this.changeSkuToAuto = this.changeSkuToAuto.bind(this);
    this.calculatePercentOfInput =
      this.calculatePercentOfInput.bind(this);
    this.checkSku = this.checkSku.bind(this);
    this.checkBarcode = this.checkBarcode.bind(this);
    this.makeid = this.makeid.bind(this);
    this.available_sku = this.available_sku.bind(this);
    this.createSkuExistence = this.createSkuExistence.bind(this);
    this.createSkuService = this.createSkuService.bind(this);
    this.onChangeProductType = this.onChangeProductType.bind(this);
    this.selectProductKind = this.selectProductKind.bind(this);
    this.setTableRows = this.setTableRows.bind(this);
    this.setProductTableRows = this.setProductTableRows.bind(this);
    this.setConfigurableName = this.setConfigurableName.bind(this);
  }

  onChangeProductType(productType) {
    this.setState({ productType });
  }

  available_sku() {
    this.calculatePercentOfInput();
    this.check_data();
  }

  getDesc(html) {
    this.setState({ html_text_descript: html });
  }

  setConfigurableName(name) {
    this.setState({ configurableName: name });
  }

  makeid(length) {
    var result = '';
    var characters = 'BCDFGHJKLMNPQRSTVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    return result;
  }

  check_data() {
    const defaultValues = { moq: 0, lead: 90 };
    let array = [];
    let nextState = {};
    let meta = {};
    let configurable_attributes = [];
    let product_lists = [];
    let configurable_payload = {};
    meta['created_by'] = localStorage.getItem('email');
    nextState['type'] = 'created_product';
    nextState['meta'] = meta;
    nextState['id'] = 0;

    document
      .querySelectorAll(
        '.new_product select, .new_product input[type=text], .new_product input[type=number]'
      )
      .forEach((select) => {
        let name = select.getAttribute('name');
        let value =
          name === 'description'
            ? select.value
            : select.value.toUpperCase().trim();
        // if value doesn't exist and the default value is defined set the default value
        value =
          !value && Object.keys(defaultValues).includes(name)
            ? defaultValues[name]
            : value;
        if (value.length !== 0) {
          let nextAttributes = {};
          if (
            name === 'simple_name' ||
            name === 'configurable_name' ||
            name === 'pack_name' ||
            name === 'kit_name'
          ) {
            name = 'name';
          }
          nextAttributes['field_name'] = name;
          nextAttributes['value'] = value;
          array.push(nextAttributes);
          if (name == 'gloss') {
            let nameAttribute = {};
            nameAttribute['field_name'] = 'name';
            nameAttribute['value'] = value;
            configurable_payload['name'] = value;

            array.push(nameAttribute);
          }
          if (select.getAttribute('duplicate') !== null) {
            let duplicate_value = {};
            duplicate_value['field_name'] =
              select.getAttribute('duplicate');
            duplicate_value['value'] = value;
            array.push(duplicate_value);
          }
          if (name === 'sku') {
            configurable_payload['sku'] = value;
            this.setState({ sku: value });
          }
        }
      });
    document
      .querySelectorAll('.new_product input[type=checkbox]')
      .forEach((input) => {
        let name = input.getAttribute('name');
        if (input.checked) {
          configurable_attributes.push(name);
        }
      });
    configurable_payload['configurable_attributes'] =
      configurable_attributes.filter((n) => n);
    array.push({
      field_name: 'configurable_attributes',
      value: configurable_attributes.filter((n) => n),
    });
    document
      .querySelectorAll('#radio_EXISTENCIA, #radio_A')
      .forEach((element) => {
        var radio = document.getElementsByName(element.name);
        for (var n = 0, l = radio.length; n < l; n++) {
          if (radio[n].checked) {
            let nextAttributes = {};
            nextAttributes['field_name'] = element.name;
            nextAttributes['value'] = radio[n].value
              .toUpperCase()
              .trim();
            array.push(nextAttributes);
          }
        }
      });
    if (this.state.selectedKind != 'configurable') {
      var valid_checked = document.getElementById('state_product');
      let checkAttributes = {};
      checkAttributes['field_name'] =
        valid_checked.getAttribute('name');
      checkAttributes['value'] = valid_checked.checked ? 'S' : 'N';
      array.push(checkAttributes);

      valid_checked = document.getElementById('id_input_vein');
      checkAttributes = {};
      checkAttributes['field_name'] =
        valid_checked.getAttribute('name');
      checkAttributes['value'] = valid_checked.checked
        ? 'vein'
        : 'no-vein';
      array.push(checkAttributes);
    }

    // Product Kind
    let product_kind = {};
    product_kind['field_name'] = 'kind';
    product_kind['value'] = this.state.selectedKind;
    array.push(product_kind);

    // Recipes

    // Principal Recipe
    let recipe_principal = {};
    recipe_principal['field_name'] = 'principal_recipe';
    recipe_principal['value'] = this.state.tableRowsPrincipal;
    array.push(recipe_principal);

    // Alternative Recipe
    let recipe_alternative = {};
    recipe_alternative['field_name'] = 'alternative_recipe';
    recipe_alternative['value'] = this.state.tableRowsAlternative;
    array.push(recipe_alternative);

    // Product List
    let product_list = {};
    product_list['field_name'] = 'product_list';
    this.state.productTableRows.forEach((product) => {
      product_lists.push(product.sku);
    });
    product_list['value'] = product_lists;
    configurable_payload['product_list'] = product_lists;
    array.push(product_list);

    nextState['attributes'] = array;
    if (this.state.selectedKind == 'configurable') {
      nextState['attributes'] = configurable_payload;
    }

    var numberInputToComplete = document
      .getElementById('number-incomplete-input')
      .innerText.split(' ')[0];
    this.setState({ allElementNotChecked: numberInputToComplete });
    if (numberInputToComplete !== 'Campos') {
      toast.error(
        `${numberInputToComplete} campos faltan por completar`
      );
    } else {
      this.checkBarcode(nextState);
    }
  }

  componentDidMount() {
    var skulenght = 20;
    document.getElementById('id_sku_random').value =
      this.makeid(skulenght);
    this.calculatePercentOfInput();
  }

  calculatePercentOfInput() {
    let selectedKind = this.state.selectedKind;
    let allElementNotChecked = [];
    var totalpercent = 100;
    var allrequired = document.querySelectorAll(
      '.was-validated input[required]:not(.trix-input):not([disabled]), .is-valid:not([disabled]), .is-invalid:not([disabled]), .was-validated select[required]:not([disabled])'
    );
    allrequired.forEach((element) => {
      var placeholder = element.getAttribute('placeholder');
      if (element.type === 'radio') {
        var radio_check = false;
        var radio = document.getElementsByName(element.name);
        for (var n = 0, l = radio.length; n < l; n++) {
          if (radio[n].checked) {
            radio_check = true;
          }
        }
        if (!radio_check) {
          allElementNotChecked.push(placeholder);
        }
      }
      if (element.value.trim().length === 0) {
        allElementNotChecked.push(placeholder);
      }
    });
    if (
      this.state.selectedKind != 'simple' &&
      this.state.selectedKind != 'configurable'
    ) {
      if (this.state.tableRowsPrincipal.length == 0) {
        allElementNotChecked.push([
          'Receta Principal',
          this.state.tableRowsPrincipal,
        ]);
      }
    } else if (this.state.selectedKind == 'configurable') {
      if (this.state.productTableRows.length == 0) {
        allElementNotChecked.push([
          'Listado de Producto',
          this.state.productTableRows,
        ]);
      }
    }
    var percentsuccess =
      totalpercent -
      Math.round(
        (allElementNotChecked.length * totalpercent) /
          (selectedKind == 'configurable'
            ? allrequired.length + 1
            : allrequired.length)
      );
    var bar = document.getElementById('percent-bar');
    var text_incomplete = document.getElementById(
      'number-incomplete-input'
    );
    var number = document.getElementById('number-percent-bar');
    bar.style.width = `${percentsuccess}%`;
    bar.setAttribute('aria-valuenow', percentsuccess);
    number.innerText = percentsuccess + '%';
    text_incomplete.classList.add(
      allElementNotChecked.length === 0
        ? 'text-success'
        : 'text-danger'
    );
    text_incomplete.classList.remove(
      allElementNotChecked.length === 0
        ? 'text-danger'
        : 'text-success'
    );
    text_incomplete.innerText =
      allElementNotChecked.length === 0
        ? 'Campos completados'
        : allElementNotChecked.length + ' campos incompletos';
    let textToToolTip = '';
    allElementNotChecked.forEach((value, key) =>
      key === 0
        ? (textToToolTip = value)
        : (textToToolTip = `${textToToolTip}, ${value}`)
    );
    text_incomplete.dataset.tip =
      allElementNotChecked !== 0 ? textToToolTip : '';
    // = allElementNotChecked

    // show sku

    if (
      document.getElementById('input_sku').getAttribute('skuauto') ===
      'true'
    ) {
      this.changeSkuToAuto();
    } else {
      let sku = document
        .getElementById('input_sku')
        .value.substring(0, 20)
        .toUpperCase();
      sku = sku.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      document.getElementById('input_sku').value = sku;
      document.getElementById('show-sku').innerHTML = `SKU: ${sku}`;
    }
  }

  // change Sku to Auto
  //
  // if checked, generate auto sku for selected type
  // then replace the value of the sku field with the auto generated one
  changeSkuToAuto() {
    let sku = '';
    document.getElementsByName('product_type').forEach((element) => {
      if (element.checked) {
        sku =
          element.value === 'SERVICIOS DE VENTA'
            ? this.createSkuService()
            : this.createSkuExistence();
      }
    });
    if (this.state.selectedKind == 'configurable') {
      sku = this.createSkuConfigurable();
    }
    sku = sku.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    sku = sku.toUpperCase();
    document.getElementById('input_sku').value = sku;
    document.getElementById('show-sku').innerHTML = `SKU: ${sku}`;
  }

  deleteBadSymbols(string) {
    return string
      .trim()
      .replace(/\s/g, '')
      .replace('/', '')
      .replace(/\\/g, '');
  }

  // Create Sku Existencia
  //
  // Create the sku for a Service product
  // Get the chars from the selected product attributes
  // returns [String] sku
  createSkuExistence() {
    var sku = '';
    var family = this.deleteBadSymbols(
      document.getElementById('attributes_select_family').value
    ).substring(0, minstring);
    var subFamily = this.deleteBadSymbols(
      document.getElementById('attributes_select_subfamily').value
    ).substring(0, maxstring);
    var gloss = this.deleteBadSymbols(
      document.getElementById('name').value
    ).substring(0, maxstring);
    var business = this.deleteBadSymbols(
      document.getElementById('select_business').value
    ).substring(0, minstring);
    var provider = this.deleteBadSymbols(
      document.getElementById('id_input_provider').value
    ).substring(0, maxstring);
    let productProviderCode = this.deleteBadSymbols(
      document.getElementById('id_product_provider_code').value
    ).substring(0, minstring);
    var barCode = this.deleteBadSymbols(
      document.getElementById('bar_code').value
    ).substring(0, minstring);
    var color = this.deleteBadSymbols(
      document.getElementById('id_input_color').value
    ).substring(0, minstring);
    var randomChar = this.deleteBadSymbols(
      document.getElementById('id_sku_random').value
    ).substring(0, maxstring);

    let filterProvider = barCode === '' ? color : barCode;
    sku = `${family}${subFamily}${gloss}${business}${productProviderCode}${filterProvider}${randomChar}${provider}`;

    return latinize(sku);
  }

  // Create Sku Service
  //
  // Create the sku for a Service product
  // get the letters from the service attributes
  // returns [String] sku
  createSkuService() {
    var sku = '';
    var gloss = this.deleteBadSymbols(
      document.getElementById('name').value.substring(0, 4)
    );
    var purchase_currency = this.deleteBadSymbols(
      document
        .getElementById('attributes_select_purchase_currency')
        .value.substring(0, minstring)
    );
    var selling_price = this.deleteBadSymbols(
      document
        .getElementById('id_input_selling_price')
        .value.substring(0, minstring)
    );
    var standar_cost = this.deleteBadSymbols(
      document
        .getElementById('id_input_standar_cost')
        .value.substring(0, minstring)
    );
    var randomChar = this.deleteBadSymbols(
      document
        .getElementById('id_sku_random')
        .value.substring(0, maxstring)
    );

    sku = `SERV${gloss}${purchase_currency}${selling_price}${standar_cost}${randomChar}HBT`;
    return latinize(sku);
  }

  // Create Sku Configurable
  //
  // Create the sku for a configurable product
  // returns [String] 14 lenght sku
  createSkuConfigurable() {
    var gloss = this.deleteBadSymbols(
      document.getElementById('name').value.substring(0, 10)
    );
    let sku = '';
    let skulenght = 14;
    sku = `C${gloss}HBT`;
    let sku_generate = sku.substring(0, sku.length - maxstring);
    let random_sku = this.makeid(
      skulenght - sku_generate.length - maxstring
    );
    let last_3 = sku.substring(sku.length - maxstring, sku.length);
    sku = `${sku_generate}${last_3}`;
    return latinize(sku);
  }

  changeStateModalChech() {
    this.setState({ modalcheck: !this.state.modalcheck });
  }

  // Change Button
  //
  // Change the button text and if its needed, disbles it
  // @returns [Null]
  changeButton(value, disable) {
    let button_save = document.getElementById('button_save');
    button_save.innerHTML = value;
    button_save.disabled = disable;
  }

  // Check Barcode
  // Calls the endpoint to check if the barcode is already in use
  // If the barcode is available, proceed to check the SKU
  // If the barcode is not available, show warning message and focus the barcode field
  // Puts a placeholder in button to show the user that the process is running
  //
  // @returns [Void] Makes differents calls depending on condition.
  checkBarcode(nextState) {
    let barcode_field = document.getElementById('bar_code');
    let button_save = document.getElementById('button_save');
    this.changeButton(es['Common']['loading'], true);

    if (this.state.selectedKind == 'configurable') {
      let sku = document.getElementById('input_sku').value;
      this.checkSku(sku, nextState, button_save);
    } else {
      barcodeAvailable(barcode_field.value).then((response) => {
        if (
          response != 'ok' &&
          this.state.selectedKind != 'configurable'
        ) {
          toast.warn(response);
          barcode_field.focus();
          barcode_field.classList.add('is-invalid');
          this.calculatePercentOfInput();
          this.changeButton(es['Common']['save_product'], false);
          this.setState({ modalcheck: false });
        } else {
          barcode_field.classList.remove('is-invalid');
          let sku = document.getElementById('input_sku').value;
          this.checkSku(sku, nextState, button_save);
        }
      });
    }
  }

  checkSku(sku, nextState, button_save) {
    let validSku = sku;
    skuAvailable(sku)
      .then((response) => {
        var skulenght = 20;
        if (this.state.selectedKind == 'configurable') {
          skulenght = 14;
        }
        if (!response) {
          toast.warn(
            'Este sku no está disponible, se ha cambiado automaticamente'
          );
          document.getElementById(
            'input_sku'
          ).value = `${sku.substring(0, 14)}${this.makeid(
            maxstring
          )}${sku.substring(17, 20)}`;
          validSku = `${sku.substring(0, 14)}${this.makeid(
            maxstring
          )}${sku.substring(17, 20)}`;
          this.calculatePercentOfInput();
        } else {
          var check = document.getElementById('check_sku_id');
          if (sku.length < skulenght) {
            if (check.checked) {
              document.getElementById('check_sku_id').click();
            }
            let sku_generate = sku.substring(
              0,
              sku.length - maxstring
            );
            let random_sku = this.makeid(
              skulenght - sku_generate.length - maxstring
            );
            let last_3 = sku.substring(
              sku.length - maxstring,
              sku.length
            );
            document.getElementById(
              'input_sku'
            ).value = `${sku_generate}${random_sku}${last_3}`;
            validSku = `${sku_generate}${random_sku}${last_3}`;
            this.calculatePercentOfInput();
            toast.warn('El SKU se autocompletado');
          }
          this.setState({ modalcheck: !this.state.modalcheck });
        }
        if (this.state.selectedKind == 'configurable') {
          nextState['attributes']['sku'] = validSku;
        }
        this.changeButton(es['Common']['save_product'], false);
        this.setState({ sku: validSku });
        this.setState({ modalcheckData: nextState });
      })
      .catch((error) => toast.error('ERROR!'));
  }

  selectProductKind(kind) {
    this.setState({ selectedKind: kind });
  }

  setTableRows(rows, recipe, action) {
    if (action == 'add') {
      if (recipe == 'principal_recipe') {
        this.setState({
          tableRowsPrincipal: [
            ...this.state.tableRowsPrincipal,
            rows,
          ],
        });
      } else {
        this.setState({
          tableRowsAlternative: [
            ...this.state.tableRowsAlternative,
            rows,
          ],
        });
      }
    } else {
      if (recipe == 'principal_recipe') {
        this.setState({ tableRowsPrincipal: rows });
      } else {
        this.setState({ tableRowsAlternative: rows });
      }
    }
  }

  setProductTableRows(rows, action) {
    if (action == 'add') {
      {
        this.setState({
          productTableRows: [...this.state.productTableRows, rows],
        });
      }
    } else {
      {
        this.setState({ productTableRows: rows });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedKind !== this.state.selectedKind) {
      this.resetFormFields();
    }
  }

  resetFormFields() {
    this.setState({
      data: '',
      cost: 0,
      sku: '',
      html_text_descript: '',
      tableRowsPrincipal: [],
      tableRowsAlternative: [],
      productTableRows: [],
      configurableName: '',
    });
  }

  selectProductKind(kind) {
    this.setState({ selectedKind: kind });
  }

  render() {
    const renderLogistic =
      this.state.productType === 'EXISTENCIA' ||
      this.state.productType === '';
    return (
      <div className="product-new">
        <ModalCheck
          modalcheck={this.state.modalcheck}
          data={this.state.modalcheckData}
          changeStateModalChech={this.changeStateModalChech}
          allElementNotChecked={this.state.allElementNotChecked}
          sku={this.state.sku}
          selectedKind={this.state.selectedKind}
        />
        <Title
          sku="Crear nuevo producto"
          calculatePercentOfInput={this.calculatePercentOfInput}
          action={'create'}
        />
        <form className="new_product Product-new container was-validated">
          <ProductSelector
            selectProductKind={this.selectProductKind}
            selectedKind={this.state.selectedKind}
            calculatePercentOfInput={this.calculatePercentOfInput}
          />
          {this.state.selectedKind != 'configurable' && (
            <Name
              calculatePercentOfInput={this.calculatePercentOfInput}
              selectedKindState={this.state.selectedKind}
            />
          )}
          {this.state.selectedKind != 'configurable' && (
            <Attributes
              calculatePercentOfInput={this.calculatePercentOfInput}
              onChangeProductType={this.onChangeProductType}
            />
          )}
          {this.state.selectedKind != 'configurable' ? (
            <Recipe
              selectedKindState={this.state.selectedKind}
              tableRowsPrincipal={this.state.tableRowsPrincipal}
              tableRowsAlternative={this.state.tableRowsAlternative}
              setTableRows={this.setTableRows}
              formKind={'new'}
            />
          ) : (
            <ProductList
              selectedKindState={this.state.selectedKind}
              productTableRows={this.state.productTableRows}
              setProductTableRows={this.setProductTableRows}
              setConfigurableName={this.setConfigurableName}
              configurableName={this.state.configurableName}
              calculatePercentOfInput={this.calculatePercentOfInput}
              formKind={'new'}
            />
          )}
          {this.state.selectedKind != 'configurable' ? (
            <Sheet
              calculatePercentOfInput={this.calculatePercentOfInput}
              getDesc={this.getDesc}
            />
          ) : (
            <CheckboxSheet
              calculatePercentOfInput={this.calculatePercentOfInput}
              getDesc={this.getDesc}
            />
          )}
          {this.state.selectedKind != 'configurable' && (
            <Price
              calculatePercentOfInput={this.calculatePercentOfInput}
            />
          )}
          {renderLogistic &&
            this.state.selectedKind != 'configurable' && (
              <Logistic
                calculatePercentOfInput={this.calculatePercentOfInput}
              />
            )}

          {this.state.selectedKind == 'configurable' && (
            <Name
              calculatePercentOfInput={this.calculatePercentOfInput}
              selectedKindState={this.state.selectedKind}
              configurableName={this.state.configurableName}
            />
          )}
          <Sku
            calculatePercentOfInput={this.calculatePercentOfInput}
            changeSkuToAuto={this.changeSkuToAuto}
            selectedKindState={this.state.selectedKind}
          />
          <input id={'id_sku_random'} disabled={true} hidden={true} />
          <div className="row justify-content-end mb-4">
            <div className="col-md-3 offset-9">
              <button
                className="btn btn-success btn-block"
                id="button_save"
                type="button"
                onClick={this.available_sku}
              >
                {es['Common']['save_product']}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default New;
