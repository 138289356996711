import React, { useState, useEffect } from 'react';

const DiscountInput = ({enabled, data, resetValueInput, setResetValue, setRealTimeDiscount}) => {

  const [newDiscount, setNewDiscount] = useState(parseInt(data.discount) * -1);


  useEffect(() => {
    if(resetValueInput) {
      setResetValue(false)
      setNewDiscount(parseInt(data.discount) * -1)
    }
  }, [resetValueInput])

  useEffect(() => {
    setNewDiscount(parseInt(data.discount) * -1)
  }, [data.discount])

  return(
    <div className='Product-show-DiscountInput'>
      <div className='row'>
        <div className='col-4'>
          <p className='mb-0'>
            {'Descuento Máximo'}
          </p>
        </div>
        <div className='col-8 mb-5'>
          <div className='input-group'>
            <div className='input-group-prepend bg-white'>
              <span className='input-group-text bg-white'>%</span>
            </div>
            <input 
              className='max-discount form-control text-right text-primary font-weight-bold discount_input' 
              type='number'
              max='50'
              min='0'
              name={'Discount_' + data.name}
              value={newDiscount}
              onChange={(event)=> {
                setNewDiscount(event.target.value);
                setRealTimeDiscount(
                  data.name,
                  event.target.value
                );
              }}
              disabled={enabled}
              oldvalue={parseInt(data.discount) * -1}
              required={data.required}
              placeholder={'Descuento Máximo ' + data.name}
              idlistaprecio={data.idListaPrecio}
              />
          </div>
        </div>
      </div>
      <hr className='mb-5'/>
    </div>
  );
}

export default DiscountInput;
