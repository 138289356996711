import global from '../data.json'

export const setProductAccept = (data, change) => {
  return fetch(`${global.api.url}/product_changes/${data.id}/${change}`, {
    method: 'POST',
    headers: {
      'x-api-token': 'd650a9e1-5bf1-4b36-adc8-3cff9c6e81f7',
      'X-USER-TOKEN': localStorage.getItem('token')
    },
    body: JSON.stringify({
      data
    })
  })
  .then( response => { return response })
  .catch( err => {
    console.log('Fetch Error :-S', err);
    return err
  });
}

export const massiveApproveChanges = (data) => {
  return fetch(`${global.api.url}/product_changes/massive_approve`, {
    method: 'POST',
    headers: {
      'x-api-token': 'd650a9e1-5bf1-4b36-adc8-3cff9c6e81f7',
      'X-USER-TOKEN': localStorage.getItem('token')
    },
    body: JSON.stringify({
      data
    })
  })
  .then( response => { return response })
  .catch( err => {
    console.log('Fetch Error :-S', err);
    return err
  });
}

// Token Login
// 
// Connect to the api with a user token to retrieve user data
// If the user is successfully logged in, store the user data in local storage
// 
// @returns [Response Object] with the login result
export const TokenLogin = (token) => {
  return fetch(`${global.api.url}/auth/token_auth`, {
    method: 'POST',
    headers: global.api.header,
    body: JSON.stringify(
      { "data": { "type": "tokens", "attributes": { "token": token }}}
    )
  })
  .then( response => response.json())
  .then( response => {
    console.log('TokenLogin Response: ', response)
    if(response.data && response.data.attributes){
      localStorage.setItem('current_role', response.data.attributes.application_roles[0]['role'])
      localStorage.setItem('token', response.data.id);
      localStorage.setItem('name', response.data.attributes.name);
      localStorage.setItem('email', response.data.attributes.email);
      localStorage.setItem('area', response.data.attributes.area);
      localStorage.setItem('expires_at', response.data.attributes.expires_at);
      localStorage.setItem('access', 'true');
    }
    return response 
  })
  .catch(function(err) { console.log('Fetch Error : ', err) });
}

export const login = data => {
  return fetch(`${global.api.url}/auth`, {
    method: 'POST',
    headers: global.api.header,
    body: JSON.stringify({
      data
    })
  })
  .then( response => response.json())
  .then( response => { 
    if(response!==400){
      localStorage.setItem('current_role', response.data.attributes.application_roles[0]['role'])
      localStorage.setItem('token', response.data.id);
      localStorage.setItem('name', response.data.attributes.name);
      localStorage.setItem('email', response.data.attributes.email);
      localStorage.setItem('area', response.data.attributes.area);
      localStorage.setItem('expires_at', response.data.attributes.expires_at);
      localStorage.setItem('access', 'true');
    }
    return response 
  })
  .catch(function(err) { console.log('Fetch Error :-S', err) });
}

export const publishProduct = (sku,type) => {
  var data = [];
  return fetch(`${global.api.url}/products/${sku}/${type}`, {
    method: 'POST',
    headers: {
      'x-api-token': 'd650a9e1-5bf1-4b36-adc8-3cff9c6e81f7',
      'X-USER-TOKEN': localStorage.getItem('token')
    },
    body: JSON.stringify({ data })
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch(function(err) { console.log('Fetch Error :-S', err) });
}

// Publish In Segment
// 
// Connect to the api with a user token to publish specified product in the specified segment
// @params sku [String] the product sku
// @params segment [String] the segment to publish the product in
// 
// @returns [Response Object] with the product change information.
export const publishInSegment = (sku, data) => {
  return fetch(`${global.api.url}/products/${sku}/publish_in_segment`, {
    method: 'POST',
    headers: {  'x-api-token': 'd650a9e1-5bf1-4b36-adc8-3cff9c6e81f7',
                'X-USER-TOKEN': localStorage.getItem('token'),
                "Content-Type": "application/json"},
    body: JSON.stringify({ data })
  })
  .then(response => response.json())
  .then(response => { return response })
  .catch(function(err) { console.log('Fetch Error :-S', err) });
}

export const updateProduct = (data, sku) => {
  return fetch(`${global.api.url}/products/${sku}/deltas`, {
    method: 'POST',
    headers: global.api.header,
    body: JSON.stringify({ data })
  })
  .then(response => response.json())
  .then( response => { return response })
  .catch(function(err) { console.log('Fetch Error :-S', err) });
}

export const updateCreateImg = (file, newImg, usage, sku, id, description, title, urlImg) => {
  var data = new FormData();
  data.append('image', file);
  data.append('usage', usage);
  data.append('description', description);
  data.append('title', title);
  if(file===''){
    data.append('url', urlImg);
  }
  console.log(...data)
  var url = `${global.api.url}/products/${sku}/photos${newImg? '' : '/'+id}`
  var method = newImg? 'POST' : 'PUT';

  return fetch(url, {
    method: method,
    headers: global.api.header_mpt_fd,
    body: data
  })
  .then(response => { return response })
  .catch(function(err) { console.log('Fetch Error :-S', err) });
}

export const deleteImg = (sku, id) => {
  return fetch(`${global.api.url}/products/${sku}/photos/${id}/delete`, {
    method: 'POST',
    headers: global.api.header
  })
  .then( response => { return response }
  )
  .catch( err => { console.log('Fetch Error :-S', err); });
}

export const promoteToMainImg = (sku, id) => {
  return fetch(`${global.api.url}/products/${sku}/photos/${id}/promote_main`, {
    method: 'POST',
    headers: global.api.header
  })
  .then( response => { return response }
  )
  .catch( err => { console.log('Fetch Error :-S', err); });
}

// Create custom product
//
// Action to create a custom product in api
// Receives a payload in JSON
// Then sends the payload to API_URL/custom_products
// 
// returns request response
export const createCustomProduct = (data) => {
  return fetch(`${global.api.url}/custom_products`, {
    method: 'POST',
    headers: global.api.header,
    body: JSON.stringify({ data })
  })
  .then((response) => { return response })
  .catch( err => { console.log('Fetch Error :-S', err) });
}

export const createProduct = (data) => {
  return fetch(`${global.api.url}/products`, {
    method: 'POST',
    headers: global.api.header,
    body: JSON.stringify({ data })
  })
  .then((response) => { return response })
  .catch( err => { console.log('Fetch Error :-S', err) });
}

export const updateCreateFile = (file, usage, sku) => {
  var data = new FormData();
  data.append('document', file);
  data.append('usage', usage);
  var url = `${global.api.url}/products/${sku}/documents`
  var method = 'POST';

  return fetch(url, {
    method: method,
    headers: global.api.header_mpt_fd,
    body: data
  })
  .then(res => { return res })
  .catch( err => { console.log('Fetch Error :-S', err) });
}

export const completePriceList = (sku) => {
  var url = `${global.api.url}/products/${sku}/generate_missing_pricelist`
  var method = 'POST';

  return fetch(url, {
    method: method,
    headers: global.api.header,
  })
  .then(res => { return res })
  .catch( err => { console.log('Fetch Error :-S', err) });
}

export const uploadStock = (file) => {
  const url = `${global.api.url}/manual_stock_upload`
  let headers = global.api.header_mpt_fd
  headers['x-user-token'] = localStorage.getItem('token')
  var data = new FormData();
  data.append('file', file);
  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: data
  })
  .then(res => { return res })
  .catch( err => { console.log('Fetch Error :-S', err) });
}

export const uploadPriceLists = (file) => {
  const url = `${global.api.url}/massive_price_changes`
  var data = new FormData();
  data.append('file', file);
  let headers = global.api.header_mpt_fd
  headers['x-user-token'] = localStorage.getItem('token')
  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: data
  })
  .then(res => { return res })
  .catch( err => { console.log('Fetch Error :-S', err) });
}

export const downloadProductExcel = (email) => {
  return fetch(`${global.api.url}/products/download_products`, {
    method: 'POST',
    mode: 'cors',
    headers: global.api.header,
    body: JSON.stringify({ "email": email })
  })
  .then( response => { return response })
  .catch(error => console.error('Error json:', error));
}
