const defaultPriceCheck = { pricesCheck: []}


function priceCheckReducer(state = defaultPriceCheck, {type, payload}) {
  switch (type){
    case "PRICE_CHECK_STATE": {
      let namePriceCheck = payload.name
      let pricesCheckRest = state.pricesCheck.filter((priceCheck) => {
        return priceCheck.name !== namePriceCheck
      })
      let pricesCheck = pricesCheckRest.concat(payload)
      return { pricesCheck: pricesCheck}
    }
    case "RESET_PRICE_CHECK_STATE": {
      return { pricesCheck: [] }
    }
    default:
      return state;
  }
}
export default priceCheckReducer;
// export default progressReducer;
