import React from 'react';

class SkuAtomatic extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: true};
    this.automaticSku = this.automaticSku.bind(this);
  }
  
  automaticSku(){
    this.props.inputEnabler();
    this.props.changeSkuToAuto();
  }

  componentDidMount(){
    document.getElementById(this.props.id).checked = true;
  }

  render() {
    return(
      <div className="form-check d-flex attributes-form pl-0 mt-1">
        <input type='checkbox' className='' id={this.props.id}  disabled={this.props.disabled} onChange={this.automaticSku}/>
        <label className='ml-3 mb-0 labelb-0' htmlFor={this.props.id}>{this.props.title}</label>
      </div>
    );
  }
}

export default SkuAtomatic;
