import React, { useEffect } from 'react';
import es from '../../es.json'

function ProductSelector(props){

  let selected = 'btn-primary'
  let unselected = 'btn-outline-primary'

  useEffect(() => {
    clearSelection()
    selectProductKind('simple')
  }, [])

  useEffect(() => {
    props.calculatePercentOfInput()
  }, [props.selectedKind])

  const selectProductKind = (id) => {
    let element = document.getElementById(id)
    props.selectProductKind(id)
    clearSelection()
    element.classList.remove(unselected)
    element.classList.add(selected)
  }

  const clearSelection = () => {
    let productSelectorButtons = document.querySelectorAll('.btn-product-selector')
    productSelectorButtons.forEach((button) => {
      button.classList.remove(selected)
      button.classList.add(unselected)
    })
  }

  return(
    <div className='row' name='product_kind_selector'>
      <div className='col-md-3 mt-2'>
        <button id='simple' className='btn btn-product-selector btn-block mt-3' type='button' onClick={ e => selectProductKind('simple') }>
          {es['ProductSelector']['simple_product']}
        </button>
      </div>
      <div className='col-md-3 mt-2'>
        <button id='configurable' className='btn btn-product-selector btn-block mt-3' type='button' onClick={ e => selectProductKind('configurable') }>
        {es['ProductSelector']['custom_product']}
        </button>
      </div>
      <div className='col-md-3 mt-2'>
        <button id='pack' className='btn btn-product-selector btn-block mt-3' type='button' onClick={ e => selectProductKind('pack') }>
          {es['ProductSelector']['pack']}
        </button>
      </div>
      <div className='col-md-3 mt-2'>
        <button id='kit' className='btn btn-product-selector btn-block mt-3' type='button' onClick={ e => selectProductKind('kit') }>
          {es['ProductSelector']['kit']}
        </button>
      </div>
    </div>
  );
}

export default ProductSelector;
