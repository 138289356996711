import React,{ useEffect, useState } from 'react';
import Required from './Required.js'

function InputTextAttributeFull(props) {

  // Converted ComponentDidUpdate
  //
  // If props change
  // Check the values in the name field
  // Then replicate the same value in the gloss
  // and in the print view, adding char limits
  // Replicate Name into gloss and print view
  //
  // returns nil
  useEffect(() => {
    if(props.value_gloss!==undefined){
      var max_print_char = 50;
      var name = document.getElementById(props.id);
      var gloss = document.getElementById(props.id_second);
      var print = document.getElementById('print_37Char'+props.id);
      name.value = props.value_name===''? props.value_gloss : props.value_name;
      gloss.value = props.value_gloss.substring(0,parseInt(props.maxlength_second));
      print.innerText = props.value_gloss.substring(0,max_print_char);
    }
  }, [props])


  // Configurable Name UseEffect
  //
  // Listen to changes in the configurableName props
  // When changed, replicate the value configurableName Value
  // Into name, gloss and print view
  // 
  // returns nil
  useEffect(()=>{
    var max_print_char = 50;
    var name = document.getElementById(props.id);
    var gloss = document.getElementById(props.id_second);
    var print = document.getElementById('print_37Char'+props.id);

    if (props.configurableName === ''){
      name.value = ''
      gloss.value = ''
      print.innerText = ''
    }else if(props.configurableName){
      name.value = props.configurableName;
      gloss.value = props.configurableName.substring(0,parseInt(props.maxlength_second));
      print.innerText = props.configurableName.substring(0,max_print_char);
    }
    props.calculatePercentOfInput()
  },[props.configurableName])


  // Change Text
  // 
  // Change the product name if product is kit or pack
  // Then add KIT or PACK at the beginning of the name
  // returns nil
  const changeText = () => {
    var max_print_char = 50;
    var name = document.getElementById(props.id);
    var value = name.value;
    let prefix = props.selectedKindState == 'kit' ? `${props.selectedKindState}_` : ''
    document.getElementById(props.id_second).value = `${prefix}${value.substring(0,parseInt(props.maxlength_second))}`
    document.getElementById('print_37Char'+props.id).innerText = value.substring(0,max_print_char);

    if(props.required){
      props.calculatePercentOfInput()
    }
  }

  // Check configurable
  //
  // Check if product type is configurable
  // If it is, hide extra elements
  // @return [String] with class to asign in div
  const checkConfigurable = () => {
    return props.selectedKindState == 'configurable' ? 'd-none' : ''
  }

  // Name field
  //
  // Check if product type is configurable
  // If it is, changes size of col of name field
  // @return [String] with class to asign in div
  const nameField = () => {
    return props.selectedKindState == 'configurable' ? 'col-md-6' : 'col-md-10'
  }

  const inputNameField = () => {
    return props.selectedKindState === undefined ? props.name : `${props.selectedKindState}_${props.name}`
  }

  return(
    <div className='row Product-show-InputTextAttributeFull mb-3'>
      <div className={`col-md-2 ${checkConfigurable()}`}>
        <p  className='mb-0' data-toggle='tooltip' data-placement='right' title={props.description_name}>
          {props.title}
          <Required required={props.required} />
        </p>
        <small className='text-secondary'>
          {props.flexline_name}
        </small>
      </div>
      <div className={`${nameField()} form-group`}>
        <input type='text' name={inputNameField()} className='form-control' id={props.id}
        placeholder={props.title} disabled={props.disabled} maxLength={props.maxlength} 
        onKeyUp={changeText.bind(this)} required={props.required}/>
      </div>

      <div className={`col-md-2 ${checkConfigurable()}`}>
        <p className='mb-0' data-toggle='tooltip' data-placement='right' title={props.description_gloss}>
          {props.title_second}
        </p>
        <small className='text-secondary'>
          {props.flexline_gloss}
        </small>
      </div>
      <div className={`col-md-10 form-group ${checkConfigurable()}`}>
        <input type='text' name={props.name_second} className='form-control' id={props.id_second} 
        placeholder={props.title_second} readOnly maxLength={props.maxlength_second}/>
      </div>

      <div className={`col-md-2 ${checkConfigurable()}`}>
        <p data-toggle='tooltip' data-placement='right' title={props.description_print}>Vista Impresión</p>
      </div>
      <div className={`col-md-10 ${checkConfigurable()}`}>
        <p className='text-monospace ml-2' id={'print_37Char'+props.id}></p>
      </div>
    </div>
  );
}
export default InputTextAttributeFull;
