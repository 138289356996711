import React, { useState, useEffect } from 'react';
// import './TitleButton.css';

function TitleButton(props){

  const [currentRole, setCurrentRole] = useState("");

  useEffect(() => {
    setCurrentRole(localStorage.getItem('current_role'))
  }, [])

  useEffect(() => {
    hiddenButton()
  }, [props])

  const changeButton = () =>{
    hiddenButton();
    props.inputEnabler();
  }

  const checkRole = (role) => {
    return role == currentRole
  }

  const hiddenButton = () =>{
    if(document.getElementById(props.id_div_save)){
      document.getElementById(props.id_div_save).hidden = props.disabled;
    document.getElementById(props.id_btn_edit).innerHTML = (!props.disabled)? 'Deshacer' : `Editar ${props.title_edit}`;
    document.getElementById(props.id_div_edit).classList.add((!props.disabled)? 'col-md-2' : 'col-md-4');
    document.getElementById(props.id_div_edit).classList.remove((!props.disabled)? 'col-md-4' : 'col-md-2');
    }
    
  }

  let buttonClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? 'col-md-4' : 'col-md-4 d-none'

  return(
    <div className='row' name='form_change_name'>
      <div className='col-md-8 mb-4'>
        <hr className='pim-border'/>
        <h5 className='m-0'>{props.title}</h5>
        {
          Array.isArray(props.description)?props.description.map((text,i) => {
            return(
            <small className='text-secondary' key={i}>{text}</small>
            );
          }) : <small className='text-secondary'>{props.description}</small>
        }
      </div>
      <div className={buttonClass} id={props.id_div_edit}>
        <button className='btn btn-primary btn-block text-white mt-3' id={props.id_btn_edit} type='button' onClick={ changeButton }>
          Editar {props.title_edit}
        </button>
      </div>
      <div id={props.id_div_save} className='col-md-2' hidden={true}>
        <button id='title_btn_send_name' className='btn btn-primary btn-block text-white mt-3' type='button' onClick={props.check_data}>
          Guardar
        </button>
      </div>
    </div>
  );
}
export default TitleButton;
