import React from 'react';
import InputTextAttribute from '../../components/InputTextAttribute';
import 'react-toastify/dist/ReactToastify.css';
// import './Sku.css';

class Sku extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: true};
  }

  componentWillReceiveProps(){
    this.setState({enabled: true});
  }
  
  render() {
    return(
      <div className='row' hidden={true}>
        <div className='col-md-5'>
          <InputTextAttribute title='SKU' name='producto' id='input_sku' value={this.props.sku}
          type='text'
          required={true} description='Codigo SKU de flexline que permite identificar en forma exclusiva a cada producto.'
          disabled={true}/>
        </div>
      </div>
    );
  }
}
export default Sku;
