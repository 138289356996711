import React from 'react';
import UpVideo from '../../components/UpVideo';
import TitleButton from '../../components/TitleButtonEdit';
import { toast } from 'react-toastify';
import ModalCheck from '../modal_check/ModalCheck';
// import './Multimedia.css';

class Multimedia extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      new_src: [],
      enabled: true,
      modalcheck: false,
      modalcheckData: [],
      sku: ''
    };

    this.inputEnabler = this.inputEnabler.bind(this);
    this.check_data = this.check_data.bind(this);
    this.changeStateModalChech = this.changeStateModalChech.bind(this);
  }

  inputEnabler(){
    this.setState({enabled: !this.state.enabled});
  }

  changeStateModalChech(){
    this.setState({modalcheck: !this.state.modalcheck});
  }

  componentWillReceiveProps(){
    this.setState({enabled: true});
  }

  check_data(){
    let array = [];
    let sku = document.getElementById('input_sku').value;
    document.querySelectorAll('.multimedia_video_form input').forEach((element) => {
      let name = element.getAttribute('name');
      let value = element.value.trim();
      if(value !== this.props[name].trim()){
        let nextState = {};
        let nextName = {};

        nextState['type'] = 'product_change';
        nextState['id'] = sku;
        nextName['sku'] = sku;
        nextName['field_name'] = name;
        nextName['field_detail'] = '';
        nextName['old_value'] = this.props[name].trim();
        nextName['new_value'] = value;
        nextName['title'] = element.getAttribute('placeholder');
        nextName['updated_by'] = localStorage.getItem('email');

        nextState['attributes'] = nextName
        array.push(nextState);
      }
    });

    if(array.length!==0){
      this.setState({modalcheck: !this.state.modalcheck});
      this.setState({modalcheckData: array});
      this.setState({sku: sku});
    }else{
      toast.warn('No se ha realizado ningun cambio');
    }
  }

  render() {
    return(
      <form className='container multimedia_video_form product-show-multimedia mb-4'>
        <ModalCheck modalcheck={this.state.modalcheck} idTable='modaltablecheckproducts' data={this.state.modalcheckData} 
        changeStateModalChech={this.changeStateModalChech} sku={this.state.sku} id_btn_edit='btn_edit_multimedia_video' 
        getProductData={this.props.getProductData}/>
        
        <TitleButton disabled={this.state.enabled} id_div_edit='div_edit_multimedia_video' id_btn_edit='btn_edit_multimedia_video' 
        title='VIDEOS MULTIMEDIA' id_div_save='div_send_multimedia_video' inputEnabler={this.inputEnabler} check_data={this.check_data} 
        title_edit='Videos' description='Agrega o edita una url de youtube'/>
      
        <div className='row'>
          <UpVideo title='Video Web' id='id_web_video' name='web_video' id_vid='id_vid_src_web_video' disabled={this.state.enabled}
          sub_title={`Agrega el código del video y presiona [ENTER] para la previsualización.`}
          example={`EJEMPLO: Toma la url "https://www.youtube.com/watch?v=ZrDJgxbDrMM", el código del video siempre viene después de "v=", es decir, el código es : ZrDJgxbDrMM`} value={this.props.web_video}/>
          <UpVideo title='Video Tienda' id='id_vid_store_video' name='store_video' id_vid='id_vid_src_store_video' disabled={this.state.enabled}
          sub_title={`Agrega el código del video y presiona [ENTER] para la previsualización.`}
          example={`EJEMPLO: Toma la url "https://www.youtube.com/watch?v=ZrDJgxbDrMM", el código del video siempre viene después de "v=", es decir, el código es : ZrDJgxbDrMM`}
          value={this.props.store_video}/>
        </div>
      </form>
    );
  }
}
export default Multimedia;
