import React from 'react';
// import './Title.css';

class Title extends React.Component {

  constructor(props) {
    super(props);
    this.state = {enabled: false};
  }

  render() {
    if(this.props.table != 'attribute_configuration'){
      return(
        <div className='row' name='form_new_name'>
          <div className='col-md-12 mb-4'>
            <hr className='pim-border'/>
            <h5 className='m-0'>{this.props.title}</h5>
            <small className='text-secondary' id={this.props.id_descr}>{this.props.description}</small>
          </div>
        </div>
      );
    }
    else{
      return(
        <div>
          <div className='row'>
            <div className='col-8'>
              <hr className='pim-border'/>
            </div>
            <div className='col-4'/>
          </div>
          <div className='row' name='form_new_name'>
            <div className='col-md-12 mb-4'>
              <h5 className='m-0'>{this.props.title}</h5>
              <small className='text-secondary' id={this.props.id_descr}>{this.props.description}</small>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default Title;
