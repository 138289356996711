import React, { useState, useEffect } from 'react';
import es from '../../../es.json'

function SkusTable(props){
  if(props.name == 'skus_list'){
    return(
      <>
        <table className='table table-borderless' name={ props.name }>
          <thead className='table-header-border'>
            <tr>
              <th className='table-text col-md-4'> { es['SkusTable']['sku'] } </th>
              <th className='table-text col-md-8'> { es['SkusTable']['description'] } </th>
            </tr>
          </thead>
          <tbody>
            {
              (props.sku_list).map((row, index) => {
                return(
                  <tr id={ `${props.name}_${index}` } key={ `${props.name}_${index}_key` }>
                    <td className='small'>{ row['sku'] }</td>
                    <td className='small'>{ row['description'] }</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </>
    )
  }
  else{
    return(
      <>
        <div className='table-responsive '>
          <table className='table table-borderless options-table' name={ props.name }>
            <thead className='table-header-border'>
              <tr>
                {
                  (['sku', ...props.attributes]).map( attr => {
                    if(attr == 'sku'){
                      return(
                        <th className='table-text table-sku-border col-auto'> {  es['SkusTable'][attr] } </th>
                      )
                    }
                    else{
                      return(
                        <th className='table-text col-auto'> {  es['SkusTable'][attr] } </th>
                      )
                    }
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                props.sku_list.map( (sku_hash, index) => {
                  return(
                    <tr id={ `${props.name}_${index}` } key={ `${props.name}_${index}_key` }>
                    {
                      ['sku', ...props.attributes].map( (attr, index) => {
                        if(index == 0){ return (
                          <td className='small table-sku-border col-2'> { sku_hash['sku'] } </td>
                        )}
                        else{
                          return(
                            <td className='small col-auto'>{ props.configurable_options[sku_hash['sku']][attr] }</td>
                          )
                        }
                      })
                    }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

export default SkusTable;
