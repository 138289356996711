import React from 'react';
// import JsBarcode from 'jsbarcode';
import InputTextAttribute from '../../show/custom_product/InputTextAttribute';
import TrixDescription from '../../components/TrixDescription';
import Title from '../../components/Title';
import es from "../../../es.json"
import './CheckboxSheet.css';
import { connect } from 'react-redux';
import SelectAtribute from '../../components/SelectAttribute';
import { getBrandsData } from '../../../api/APIGet'
import sizes from '../../../api/sizeTypeFlexline.json'
import packages from '../../../api/packageTypeFlexline.json'
import spaces from '../../../api/spaceTypeFlexline.json'

// import './Sheet.css';

// Render The Product Sheet but with checkbox instead of inputs or selects
class Sheet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
    };
  }

  render() {
    return(
      <div className='row'>
        <div className='col-md-12 mt-2'>
        <Title title={ es['Common']['attributes'].toUpperCase() } check_data={this.check_data}/>
        </div>
        <div className='col-md-6'>
          <InputTextAttribute 
          measurement={true}
          title='Alto(cm)' name='height' id='id_input_height'
          type='checkbox'
          min='0'
          description='Alto o Largo del producto.'
          flexline_name=''/>
        </div>
        <div className='col-md-6'>
          <InputTextAttribute 
          measurement={true}
          title='Profundidad(cm)' name='lenght' id='id_input_lenght'
          type='checkbox'
          min='0'
          description='Profundidad o espesor del producto.'
          flexline_name=''/>
        </div>
        <div className='col-md-6'>
          <InputTextAttribute 
          measurement={true}
          title='Ancho(cm)' name='width' id='id_input_width'
          type='checkbox'
          min='0'
          description='Ancho del producto'
          flexline_name=''/>
        </div> 
        <div className='col-md-6'>
          <InputTextAttribute title='Color' name='color' id='id_input_color'
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          type='checkbox'
          notRequired={true}
          description='Color del producto'
          flexline_name=''/>
        </div> 

        <div className='col-md-6'>
          <InputTextAttribute title='Material' name='materiality' id='id_input_materiality' 
          type='checkbox'
          description='Material de confección del producto'
          flexline_name=''/>
        </div>
 
        <div className='col-md-6'>
          <InputTextAttribute  
          id='id_input_brand' 
          title='Marca' 
          name='brand'
          type='checkbox'
          description='Marca del producto.'
          flexline_name = ''/>
        </div>

        <div className='col-md-6'>
          <InputTextAttribute title='Tipo de espacio' name='space_type' id='id_input_space_type' 
          type='checkbox'
          description='Indica el lugar deberia establecerse el producto'
          flexline_name=''/>
        </div>

        <div className='col-md-6'>
          <InputTextAttribute title='Colección' name='collection' id='id_input_collection' 
          type='checkbox'
          description=''
          flexline_name=''/>
        </div> 

        <div className='col-md-6'>
          <InputTextAttribute  
          id='id_input_size' 
          title='Tamaño' 
          type='checkbox'
          name='size' 
          description='Indica el tamaño del producto'
          flexline_name = ''/>
        </div>

        <div className='col-md-6'>
          <InputTextAttribute 
          id='id_input_package_type'
          type='checkbox'
          title='Tipo de Empaque' 
          name='package_type' 
          description='Indica el peso del bulto de origen(por caja)'
          flexline_name = ''/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requiredSheet: state.elementRequiredReducer.sheet
})

const mapDispatchToProps = (dispach) => ({
})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Sheet)

export default component;