import React from 'react';
import './Components.css';
import logo from '../img/Logo.png';
import { Link } from 'react-router-dom';

function Title() {
  return (
    <div className='Title mr-3'>
      <Link to='/'>
        <img className='logo ml-2' src={logo} alt='logo' />
      </Link>
    </div>
  );
}

export default Title;
