import React from 'react';
import { TrixEditor } from 'react-trix';
import 'trix'
import 'trix/dist/trix.css';
import Required from './Required.js'

function TrixEdit(props){
  if(!props.disabled){
    return(
      <TrixEditor 
        onChange={props.handleChange.bind(this)} 
        onEditorReady={props.handleEditorReady} 
        value={props.value}
        placeholder='Descripción'
      />
    );
  }else{
    return null;
  }
}
class InputTextAttribute extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
    };
    this.handleEditorReady = this.handleEditorReady.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleEditorReady(editor) {
    // console.log('Editor Ready Props: ',this.props.value);
    // editor.insertString(this.props.value);
  }

  handleChange(html, text) {
    // this.props.getDesc(html);
    document.getElementById('trix-editor-input').value = html
    if(this.props.required){
      this.props.calculatePercentOfInput()
    }
  }

  componentDidUpdate(){
    document.getElementById('trix-editor-input').value = this.props.value;
  }
 
  render() {
    let {description, title, required, disabled, value, name, fromDescription} = this.props
    return(
      <div className='row Product-show-InputTextAttribute mb-2'>
        <div className='col-md-2'  data-toggle='tooltip' data-placement='right' title={description}>
          <p>{title}</p>
          <Required required={required} />
        </div>
        <div className={`col-md-${fromDescription? '12' : '10'}`} hidden={!disabled}>
          <div className={`border rounded bg-grey p-2 ${value===''? 'non-data-descript' : ''}`} dangerouslySetInnerHTML={{__html: value}} />
        </div>
        <div className={`col-md-${fromDescription? '12' : '10'}`} hidden={disabled}>
          <TrixEdit 
          disabled={disabled}
          handleChange={this.handleChange} 
          handleEditorReady={this.handleEditorReady}
          value={value}/>
        </div>
        <div className='form-group' hidden={true}>
          <div className='input-group'>
            <input id='trix-editor-input' name={name} type='text' placeholder={title}
            required={required} className='form-control'></input>
          </div>
        </div>
      </div>
    );
  }
}
export default InputTextAttribute;