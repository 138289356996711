import React, { useState, useEffect } from 'react';
import TitleButton from '../../components/TitleButtonEdit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalCheck from '../modal_check/ModalCheck';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

const TechnicalProducts = (props) => {
  const [enabled, setEnabled] = useState(true);
  const [modalcheck, setModalCheck] = useState(false);
  const [modalcheckData, setModalCheckData] = useState([]);
  const [sku, setSku] = useState('');
  const [technicalProducts, setTechnicalProducts] = useState([]);

  useEffect(() => {
    setEnabled(true);
    setTechnicalProducts(props.technical_products);
  }, [props.technical_products]);

  const inputEnabler = () => {
    setEnabled(!enabled);
  };

  const changeStateModalCheck = () => {
    setModalCheck(!modalcheck);
  };

  const checkData = () => {
    let array = [];
    let skuValue = document.getElementById('input_sku').value;
    let name = 'technical_products';
    let value = technicalProducts.slice(); // Creating a copy of the array

    let nextState = {
      type: 'product_change',
      id: skuValue,
      attributes: {
        sku: skuValue,
        field_name: name,
        field_detail: '',
        old_value: props.technical_products,
        new_value: value,
        title: 'Tapacantos Relacionados',
        updated_by: localStorage.getItem('email'),
      },
    };

    array.push(nextState);

    if (array.length !== 0) {
      setModalCheck(!modalcheck);
      setModalCheckData(array);
      setSku(skuValue);
    } else {
      toast.warn('No se ha realizado ningun cambio');
    }
  };

  const handleChange = (tags) => {
    setTechnicalProducts(tags);
  };

  let { description, title, flexline_name, id, disabled } = props;

  return (
    <div className='row Product-show-InputTextAttribute'>
      <div className={`col-md-2`} data-toggle='tooltip' data-placement='right' title={description}>
        <p className='mb-0 pb-0'>{title}</p>
        <small className='text-secondary'>{flexline_name}</small>
      </div>
      <div className={`form-group mb-0 col-md-10`}>
        <TagsInput
          id={id}
          inputProps={{
            placeholder:
              'Agrega el Producto Tecnico relacionado. Ingresa el sku y presiona [ENTER] para agregarlo',
            name: `${props.name}`,  
          }}
          value={technicalProducts}
          onChange={handleChange}
          disabled={disabled}
          removeKeys={[7]}
        />
        <div className='form-group' hidden={true}>
          <div className='input-group'>
            <input
              id='related-products-sku'
              name={props.name}
              type='text'
              placeholder={title}
              className='form-control'
              value={technicalProducts}
              onChange={(e) => setTechnicalProducts(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalProducts;