import React from 'react';
import Popup from 'reactjs-popup';
import { updateCreateImg, deleteImg, promoteToMainImg } from '../../api/APIPost';
import AddImg from '../../img/addimg.png';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import InputTextAttribute from './InputTextAttribute'
import TextAreaAttribute from './TextAreaAttribute'

const minMagicInfoHeight = 1920;
const minMagicInfoWith = 1080;
const minFotoAttribHeight = 1000;
const minFotoAttribWidth = 1000;
const minFotoTecHeight = 1000;
const minFotoTecWidth = 1000;
const minFotoDescHeight = 540;
const minFotoDescWidth = 488;

class UpImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalcheck: false,
      changeImage: true,
      modaldelete: false,
      image_src: '',
      loadImg:false,
      second_to_change: 3000,
      class_name_img_full: 'img-full'
    };

    this.sendImg = this.sendImg.bind(this);
    this.acceptImage = this.acceptImage.bind(this);
    this.openImage =this.openImage.bind(this);
    this.changeStateModalImg = this.changeStateModalImg.bind(this)
    this.requiredSizeImg = this.requiredSizeImg.bind(this)
  }

  changeImage(){ 
    var file    = document.getElementById(this.props.id);
    var img_full    = document.getElementById('img_full_'+this.props.id_img);
    if(img_full!==null && file.files[0]!==undefined){
      img_full.src = window.URL.createObjectURL(file.files[0]);
      this.setState({changeImage: false});
    }
  }

  getFile() {
    document.getElementById(this.props.id).click();
  }

  acceptImage() {
    let {id, id_img, usage } = this.props
    document.getElementById('btn_acceptImg_'+id).disabled = true;
    this.changeStateModalImg()
    var element = document.getElementById(id);
    let img_full = document.getElementById('img_full_'+id_img)
    var file = element.files[0]!==undefined? element.files[0] : '';
    let description = document.getElementById(`description-id-img-`+id_img)
    let descriptionValue = description!==null? description.value.trim() : ''
    let title = document.getElementById(`title-id-img-`+id_img).value
    title = title.replace(/[^a-z0-9,. ]/gi, '')

    switch (id) {
      case 'id_fot_encomienda':
        this.setState({loadImg: true});
        this.sendImg(file,true, title)
        break;
      case 'id_fot_desc':
        this.requiredSizeImg(img_full, file, title, true, minFotoDescHeight, minFotoDescWidth)
        break;
      case 'id_fot_amb':
      case 'id_fot_tec':
        this.requiredSizeImg(img_full, file, title, true, minFotoTecHeight, minFotoTecWidth)
        break;
      case 'id_fot_attribute':
        descriptionValue!==''?
          this.requiredSizeImg(img_full, file, title, true, minFotoAttribHeight, minFotoAttribWidth) :
          toast.warn('La Fotografía necesita una descripción')
        break;
      case 'id_fot_mag':
        this.requiredSizeImg(img_full, file, title, true, minMagicInfoHeight, minMagicInfoWith);
        break;

      default:

        if(usage==='MagicInfo'){
          this.requiredSizeImg(img_full, file, title, false, minMagicInfoHeight, minMagicInfoWith);
        }else if(usage==='FotoTecnica'||usage==='FotoAmbientada'){
          this.requiredSizeImg(img_full, file, title, false, minFotoTecHeight, minFotoTecWidth);
        }else if(usage==='FotoAtributo'){
          descriptionValue!==''?
            this.requiredSizeImg(img_full, file, title, false, minFotoAttribHeight, minFotoAttribWidth) :
            toast.warn('La Fotografía necesita una descripción')
        }else if(usage==='FotoDescripcion'){
          this.requiredSizeImg(img_full, file, title, false, minFotoDescHeight, minFotoDescWidth);
        }else{
          toast.error('No existe usage definido para esta imagen')
          this.setState({loadImg: false})
        }
        break;
    }
  }

  requiredSizeImg(child, file, title, newImg, maxHeight, maxWidth ){
    if(child.naturalHeight <= maxHeight && child.naturalWidth <= maxWidth){
      this.setState({loadImg: true});
      this.sendImg(file,newImg, title);
    } else {
      toast.error(`La Fotografía excede el tamaño maximo (Fotografia: ${child.naturalHeight} x ${child.naturalWidth},\n Maximo: ${maxHeight} x ${maxWidth})`)
      this.setState({loadImg: false})
    }
    
  }

  sendImg(file, newImg, title){
    let { usage, sku, id, getProductData, id_img } = this.props
    let description = document.getElementById(`description-id-img-`+id_img)
    let descriptionValue = description!==null? description.value.trim() : ''
    let url = this.state.image_src
    updateCreateImg(file, newImg, usage, sku, id, descriptionValue, title, url)
    .then(response => {
      if (response.status===204) {
        toast.success('La imagen se subió exitosamente!');
        setTimeout( () => {
          getProductData(sku);
          this.setState({loadImg: false})
        }, this.state.second_to_change);
      } else {
        toast.warn('No se a podido subir su imagen');
        this.setState({loadImg: false})
      }
    }, function (e) {
      toast.error('Ha ocurrido un error');
      this.setState({loadImg: false})
    });
  }

  openImage() {
    let currentRole = localStorage.getItem('current_role')
    if(currentRole == 'none'){
      return
    }

    if(this.props.input_name===null && !this.state.modalcheck){
      if(currentRole == 'basic'){return}
      document.getElementById(this.props.id).click();
    }
    this.changeStateModalImg();

    var img = document.getElementById(this.props.id_img);
    if(img.naturalHeight>img.naturalWidth){
      this.setState({class_name_img_full: 'img-only-height'})
    }else{
      this.setState({class_name_img_full: 'img-full'})
    }
  }

  closeImg(){
    this.changeStateModalImg()
  }

  setDeleteImg(){
    document.getElementById('btn_DeleteImg_'+this.props.id).disabled = true;
    this.changeStateModalImg();
    this.setState({loadImg: true});
    var sku = this.props.sku
    deleteImg(sku, this.props.id).then( response => {
      toast.success('Cambio realizado');
      setTimeout( () => {
        this.props.getProductData(sku);
      }, this.state.second_to_change);
    }).catch( err => toast.error('No se ha podido mandar los datos, vuelva a intentarlo') )
  }

  componentWillReceiveProps(props){
    this.setState({image_src: props.src===null? AddImg : props.src});
  }

  changeStateModalImg(){
    this.setState({modalcheck: !this.state.modalcheck});
    this.setState({changeImage: true});
  }

  updateImgToMain(){
    document.getElementById('btn_SetImg_'+this.props.id).disabled = true;
    this.changeStateModalImg()
    this.setState({loadImg: true});
    var sku = this.props.sku;
    promoteToMainImg(sku, this.props.id)
    .then( response => {
        toast.success('Cambio realizado');
        setTimeout( () => {
          this.props.getProductData(sku);
        }, this.state.second_to_change);
      }
    ).catch( err => toast.error('No se ha podido mandar los datos, vuelva a intentarlo') );
  }

  render() {
    if(this.state.loadImg){
      return(
        <div className='ml-2 mt-1'>
          <ReactLoading delay={this.state.second_to_change/1000} color={'#544f92'} type='bars'/>
        </div>
      )
    }
    let currentRole = localStorage.getItem('current_role')
    let {hidden, input_name, src, disabled_select_img, disabled_btn_edit, name, id_img, id, disabled_btn_delete, title, description, usage} = this.props
    let {image_src, modalcheck, class_name_img_full, modaldelete} = this.state
    let showClass = currentRole == 'basic' ? 'd-none' : ''
    let editTittle = currentRole == 'basic'? true : false
    return(
      <div className='Product-show-UpImage d-flex'>
        <img id={id_img} src={this.props.src===null? AddImg : this.props.src} alt='img'
        className='image-up add-img ml-2 mt-2 text-center border border-primary btn-file' 
        name={name} onClick={() => this.openImage()} hidden={hidden}/>
        <input name={input_name} id={id} type='file' accept='image/jpeg' 
        disabled={disabled_btn_edit} hidden={true} onChange={this.changeImage.bind(this)}
        old_value={src}/>
        <Popup
          open={modalcheck}
          closeOnDocumentClick
        >
          <div className='row'>
            <div className='col-4 mb-2'>
              <button type='button' className={`btn btn-danger ${showClass}`} onClick={() => this.setState({modaldelete: true})}
              hidden={disabled_btn_delete} >Eliminar Imagen</button>
            </div>
            <div className='col-4 text-center mb-2'>
              <button id={'btn_SetImg_'+id} type='button' className={`btn btn-primary mr-2 ${showClass}`} onClick={this.updateImgToMain.bind(this)} 
              hidden={disabled_select_img}>
                Seleccionar como ambientada
              </button>
            </div>
            <div className='col-4 text-right mb-2'>
              <button id={'btn_acceptImg_'+id} type='button' className={`btn btn-success mr-2 ${showClass}`} onClick={this.acceptImage}>
                {disabled_btn_delete? 'Agregar' : 'Actualizar' }
              </button>
            </div>
            <div className={`col-7 text-center`}>
              <div className='col-12 text-center d-flex align-items-center'>
                <img id={`img_full_`+id_img} src={this.props.src===null? AddImg : this.props.src} className={`img_preview_full col-12 ${class_name_img_full}`} name={'img_full_'+name} alt='img'/>
              </div>
              <button type='button' className= {`btn btn-primary mr-2 mt-2 button ${showClass}`} onClick={this.getFile.bind(this)} hidden={disabled_btn_edit}>Cambiar Imagen</button>
            </div>
            <div className='col-5 text-left pr-5'>
              <InputTextAttribute title='Título' name={`title-name-img-${name}`} id={`title-id-img-`+id_img} 
              value={title===undefined||title===null? '' : title} fromDescription={true} required={false} disabled={editTittle}/>
              {usage==='FotoAtributo'?
              <TextAreaAttribute title='Descripción' name={`description-name-img-${name}`} id={`description-id-img-`+id_img} 
              value={description===undefined||description===null? '' : description} /> : null }
            </div>
            <div className='col-12'>
              <button type='button' className='btn btn-primary mt-2' onClick={() => this.closeImg()}>Volver</button>
            </div>
          </div>
        </Popup>
        <Popup
          open={modaldelete}
          closeOnDocumentClick
        >
          <div className='container'>
            <div className='row'>
              <div className='col-12 text-center'>
                <h2>¿Estas seguro que quieres eliminar esta fotografia?</h2>
              </div>
              <div className='col-6 text-left'>
                <button id={'btn_DeleteImg_'+id} type='button' className='btn btn-danger' onClick={this.setDeleteImg.bind(this)}
                hidden={disabled_btn_delete} >Eliminar</button>
              </div>
              <div className='col-6 text-right'>
                <button type='button' className='btn btn-primary' onClick={() => this.setState({modaldelete: false})}>Volver</button>
              </div>
            </div>
          </div>
        </Popup>
      </div>
        
    );
  }
}
export default UpImage;
