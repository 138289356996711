import React, { useState, useEffect } from 'react';
import { updateCreateFile } from '../../api/APIPost';
import { deleteUploadFile } from '../../api/APIGet';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

function UpSeeFile(props){
  const[currentRole, setCurrentRole] = useState("");

  useEffect(() => {
    setCurrentRole(localStorage.getItem('current_role'))
  }, [])

  const checkRole = (role) => {
    return role == currentRole
  }

  let roleClass =  (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? 'col-md-3 pl-0' : 'col-md-3 pl-0 d-none'

  if(props.loadFile){
    return(
      <ReactLoading delay={props.second_to_change/1000} color={'#544f92'} type='bars'/>
    )
  }else{
    return(
      <div className='row'>
        <div className='col-md-6 pl-3'>
          <button className='btn btn-primary btn-file btn-sm btn-block' type='button' onClick={props.openFile} disabled={props.disabled}>
            Subir Archivo
          </button>
        </div>
        <div className='col-md-3 pl-0'>
          <button className='btn btn-outline-primary btn-sm btn-block' type='button' hidden={props.hiddenfile} onClick={props.reviewFile}>
            Ver
          </button>
        </div>
        <div className={roleClass}>
          <button className='btn btn-danger btn-sm btn-block' type='button' hidden={props.hiddenfile} onClick={() => { if (window.confirm('¿Estás seguro de eliminar este archivo?')) props.deleteFile() } }>
            Borrar
          </button>
        </div>
      </div>
  )
  }
}
class UpFilePDF extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      hiddenfile: true,
      second_to_change: 2000,
      loadFile: false
    }
    this.sendImg = this.sendImg.bind(this);
    this.openFile = this.openFile.bind(this);
    this.reviewFile = this.reviewFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.acceptFile = this.acceptFile.bind(this);
  }

  openFile(){
    var file = document.getElementById(this.props.id)
    if(file!==null){
      document.getElementById(this.props.id).click();
    }
  }

  componentWillReceiveProps(){
    this.setState({ hiddenfile: true })
    if (this.props.value.length!==0) {
      this.props.value.forEach(element => {
        if(this.props.usage === element.usage){
          this.setState({ hiddenfile: false})
          document.getElementById(`${this.props.id}_see_file`).value = element.url;
        }
      });
    }
  }

  acceptFile() {
    var element = document.getElementById(this.props.id);
    var file = element.files[0];
    this.sendImg(file);
  }

  sendImg(file){
    this.setState({loadFile: true});
    var sku = this.props.sku;
    updateCreateFile(file, this.props.usage, sku)
    .then(res => {
      if (res.status===204) {
        setTimeout( () => {
          toast.success('El documento se subió exitosamente!');
          this.props.getProductData(sku);
          this.setState({loadFile: false})
        }, this.state.second_to_change);
        setTimeout( () => {
          this.props.getProductData(sku);
        },2000)
      } else { toast.warn('No se a podido subir su imagen'); }
    })
    .catch( err => toast.error('Ha ocurrido un error') );
  }
  
  deleteFile(){
    this.setState({loadFile: true});
    var sku = this.props.sku;
    var usage = this.props.usage;
    var value = this.props.value;
    var id = ''
    
    value.forEach( val => {
      if (val.usage === usage) {
         id = val.document_id
      }
    })

    deleteUploadFile(sku, id)
    .then(res =>{
      if(res.status===200){
        setTimeout( () => {
          toast.success('Archivo eliminado correctamente.')
          this.props.getProductData(sku);
          this.setState({loadFile: false})
        }, this.state.second_to_change);
        setTimeout( () => {
          this.props.getProductData(sku);
        },2000)
      }else{
        toast.warn('No se a podido eliminar su archivo'); 
      }
    })
    .catch( err => toast.error('Ha ocurrido un error') );
  }

  reviewFile(){
    window.open(document.getElementById(`${this.props.id}_see_file`).value);
  }

  render() {
    
    return(
      <div className='row Product-show-UpFilePDF'>
        <div className='col-md-4'><p>{this.props.title}</p></div>
        <div className='col-md-8'>
          <UpSeeFile second_to_change={this.state.second_to_change} openFile={this.openFile} disabled={this.props.disabled}
            hiddenfile={this.state.hiddenfile} reviewFile={this.reviewFile} deleteFile={this.deleteFile} loadFile={this.state.loadFile}/>
          
          <input id={this.props.id}  name={this.props.name} type='file' hidden={true} disabled={this.props.disabled} 
          accept='application/pdf,application/vnd.ms-excel' onChange={this.acceptFile} />
          <input id={this.props.id+'_see_file'} hidden={true} disabled={true} />
        </div>
      </div>
    );
  }
}
export default UpFilePDF;
