import requiredState from '../../api/stateRequired.json';

const defaultState = requiredState['EXISTENCIA'];

function reducer(state = defaultState, { type, typeSelected }) {
  let newState = state; // Assign the current state as the default

  switch (type) {
    case "CHANGE_REQUIRED":
    case "PRODUCT_LOADED":
      newState = requiredState[typeSelected] || state; // Update state based on action typeSelected
      break;
    case "RESET_REQUIRED":
      newState = defaultState; // Reset state to the default state
      break;
    default:
      break;
  }

  return newState;
}

export default reducer;
