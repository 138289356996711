import React from 'react';
import es from '../../es.json'
import ImgNotFound from '../../img/ImgNotfound.png';

function ProductItemList(props){

  const clickItemProduct = () => {
    document.getElementById('product-detail').scrollTop = 0;

    // color active whern select a item

    if(document.getElementsByClassName('active')[0]!==undefined){
      document.getElementsByClassName('active')[0].classList.remove('active')
    }
    selectActiveItem(props.product.id)
    
  }

  const selectActiveItem= (id) =>{
    var selected_item = document.getElementById('item_'+id)
    var list_text_sku = document.getElementById('list_text_sku_'+id)
    var list_text_kind = document.getElementById('list_text_kind_'+id)
    var list_text_code = document.getElementById('list_text_code_'+id)
    if(selected_item!==null){
      selected_item.classList.add('active');
      list_text_sku.classList.remove('text-secondary');
      list_text_kind.classList.remove('text-secondary');
      list_text_code.classList.remove('text-secondary');
    }
  }
  
  const componentDidMount= () =>{
    let id = window.location.pathname.split('/')[2]
    if(id!==undefined){
      selectActiveItem(id)
    }
  }

  var t = es['ProductItemList'];
  const product = props.product;
  var published = t[product.attributes.status];
  var photo = ImgNotFound;
  const Link = require('react-router-dom').Link;
  
  if(product.attributes.photos.length!==0){
    product.attributes.photos.forEach(element => {
      if(element.usage==='thumbnail'){
        photo = element.url;
      }
    });
  }

  return(
    <Link to={{pathname:`/product/${product.attributes.sku}`, product: product}} 
    className='list-group-item list-group-item-action py-0' 
    onClick={clickItemProduct}
    id={'item_'+product.id}>
      <div className='row'>
        <div className='col-md-4 text-center pb-2'>
          <img src={photo} className='img-product mt-3 text-center border border-primary' alt='Imagen'/>
        </div>
        <div className='col-md-8'>
          <p className='h6 mt-3 mb-3'>{product.attributes.description}</p>
          <p id={'list_text_sku_'+product.id} className='mb-0 small text-secondary'>{ `${es["Common"]["sku"]}:`}  { product.attributes.sku }</p>
          <p id={'list_text_kind_'+product.id} className='mb-0 small text-secondary'>{ `${es["Common"]["product_kind"]}:`}  { product.attributes.product_kind }</p>
          <div className='row'>
            <div className='col-md-6'>
              <p id={'list_text_code_'+product.id} className='small text-secondary'>{ `${es["Common"]["code"]}:`}  { product.attributes.code }</p>
            </div>
            <div className='col-md-6 text-center text-white'>
              <p className={`rounded ${published==='Creado'? 'bg-info' : published==='Pendiente'? 'bg-warning' : 'bg-success' }`}>{published===undefined? 'Estado' : published}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ProductItemList;

// src={product.attributes.photos.lenght===0? '' : product.attributes.photos[0].url}