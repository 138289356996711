import React, { useState, useEffect } from 'react';
import './published.css';
import CheckBoxPublish from '../../components/CheckBoxPublish';
import PublishSegment from '../../components/PublishSegment';
import Popup from 'reactjs-popup';
import { publishProduct } from '../../../api/APIPost';
import { toast } from 'react-toastify';
// import './Name.css';

const Published = ({
  status,
  publish_segment,
  barPercentage,
  ...props
}) => {
  const [hideButton, setHideButton] = useState(true);
  const [currentRole, setCurrentRole] = useState('');
  const [showModal, setShowModal] = useState(false);

  const segments = [
    { id: 'B2B', type: 'B2B' },
    { id: 'B2C', type: 'B2C' },
    { id: 'B2B-B2C', type: 'B2B y B2C' },
  ];

  useEffect(() => {
    setCurrentRole(localStorage.getItem('current_role'));
  }, [barPercentage]);

  useEffect(() => {
    loadData();
  }, [props]);

  const checkRole = (role) => {
    return role == currentRole;
  };

  useEffect(() => {
    const b2b = document.getElementById('B2B');
    const b2c = document.getElementById('B2C');
    const b2b_b2c = document.getElementById('B2B-B2C');
    const publishButton = document.getElementById('publish');

    if (
      !b2b.checked &&
      !b2c.checked &&
      !b2b_b2c.checked &&
      status === 'published'
    ) {
      b2b.disabled = true;
      b2c.disabled = true;
      b2b_b2c.disabled = true;

      let kind = publishButton.checked ? 'unpublish' : 'unpublish';
      let sku = document.getElementById('input_sku').value;

      if (currentRole == 'basic') {
        return;
      }

      publishProduct(sku, kind)
        .then((response) => {
          if (
            response.data.attributes.aasm_state == 'waiting_approval'
          ) {
            return toast.info(
              'El cambio fue solicitado y se está procesando'
            );
          }

          if (response) {
            publishButton.checked = false;
          }
        })
        .catch((err) => {
          toast.error(
            'No se ha podido mandar los datos, vuelva a intentarlo'
          );
        })
        .finally(() => {
          b2b.disabled = false;
          b2c.disabled = false;
          b2b_b2c.disabled = false;
        });
    }
  }, []);

  // Load Data
  // On commponent load, retrieves data from the document and the products
  // And sets initial values for the published section in product detial
  //
  // @return [Void] Set initial values.
  const loadData = () => {
    setHideButton(barPercentage !== 100);
    let btnPublish = document.getElementById('publish');
    let btnPublishText = document.getElementById('publish-text');
    let published_b2b = document.getElementById('B2B');
    let published_b2c = document.getElementById('B2C');
    let published_b2b_b2c = document.getElementById('B2B-B2C');

    const b2b = document.getElementById('B2B');
    const b2c = document.getElementById('B2C');
    const b2b_b2c = document.getElementById('B2B-B2C');
    const publishButton = document.getElementById('publish');

    if (
      !b2b.checked &&
      !b2c.checked &&
      !b2b_b2c.checked &&
      !publishButton.checked
    ) {
      btnPublish.checked = false;
      btnPublishText.innerText = publishButton.checked
        ? 'PUBLICADO'
        : 'PUBLICABLE';
    } else {
      btnPublish.checked = status === 'published' ? true : false;
      btnPublishText.innerText =
        status === 'published' ? 'PUBLICADO' : 'PUBLICABLE';
    }

    if (
      publish_segment.includes('B2B') &&
      publish_segment.includes('B2C')
    ) {
      published_b2b_b2c.checked = true;
    } else if (publish_segment.includes('B2B')) {
      published_b2b.checked = true;
    } else if (publish_segment.includes('B2C')) {
      published_b2c.checked = true;
    } else {
      published_b2b = false;
      published_b2c = false;
      published_b2b_b2c = false;
    }
  };

  let roleClass =
    checkRole('basic') ||
    checkRole('manager') ||
    checkRole('supervisor') ||
    checkRole('admin')
      ? ''
      : 'd-none';

  return (
    <div
      id="container_published"
      className={`${roleClass}`}
      name="form_change_name"
      hidden={hideButton}
    >
      <Popup
        open={showModal}
        closeOnDocumentClick={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
      >
        <div
          className="p-5"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <p className="text-center">
            No puedes publicar un producto sin un segmento de
            publicación
          </p>

          <button
            className="btn btn-primary"
            onClick={() => setShowModal(false)}
          >
            OK
          </button>
        </div>
      </Popup>
      <div className="row">
        <ul className="ks-cboxtags pl-2">
          <CheckBoxPublish
            id="publish"
            type="PUBLICABLE"
            currentRole={currentRole}
            setShowModal={setShowModal}
          />
          <PublishSegment
            segments={segments}
            currentRole={currentRole}
          />
        </ul>
      </div>
    </div>
  );
};
export default Published;
