import React from 'react';
// import JsBarcode from 'jsbarcode';
import InputTextAttribute from '../../components/InputTextAttribute';
import TrixDescription from '../../components/TrixDescription';
import Title from '../../components/Title';
import './Sheet.css';
import { connect } from 'react-redux';
import SelectAtribute from '../../components/SelectAttribute';
import SwitchRadioButton from '../../components/SwitchRadioButton';
import { getBrandsData } from '../../../api/APIGet'
import sizes from '../../../api/sizeTypeFlexline.json'
import packages from '../../../api/packageTypeFlexline.json'
import spaces from '../../../api/spaceTypeFlexline.json'

// import './Sheet.css';

class Sheet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      brands: []
    };
  }

  componentDidMount(){
    getBrandsData().then( response =>  this.setState({ brands: response.data.attributes}) ) 
  }

  render() {
    // let { brandRequired, descriptionRequired } = this.props.requiredSheet
    return(
      <div className='row'>
        <div className='col-md-12 mt-2'>
        <Title title='FICHA TÉCNICA' check_data={this.check_data} description='Para la confección de fichas automáticas'/>
        </div>
        <div className='col-md-6'>
          <InputTextAttribute 
          measurement={true}
          title='Alto(cm)' name='height' id='id_input_height'
          type='number'
          min='0'
          description='Alto o Largo del producto.'
          flexline_name=''/>
        </div>
        <div className='col-md-6'>
          <InputTextAttribute 
          measurement={true}
          title='Profundidad(cm)' name='lenght' id='id_input_lenght'
          type='number'
          min='0'
          description='Profundidad o espesor del producto.'
          flexline_name=''/>
        </div>
        <div className='col-md-6'>
          <InputTextAttribute 
          measurement={true}
          title='Ancho(cm)' name='width' id='id_input_width'
          type='number'
          min='0'
          description='Ancho del producto'
          flexline_name=''/>
        </div> 
        <div className='col-md-6'>
          <InputTextAttribute title='Color' name='color' id='id_input_color'
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          type='text'
          notRequired={true}
          description='Color del producto'
          flexline_name='analisisproducto27'/>
        </div> 

        <div className='col-md-6'>
          <InputTextAttribute title='Material' name='materiality' id='id_input_materiality' 
          type='text'
          description='Material de confección del producto'
          flexline_name='analisisproducto28'/>
        </div>
 
        <div className='col-md-6'>
          <SelectAtribute 
          id='id_input_brand' 
          options={this.state.brands} 
          title='Marca' 
          name='brand' 
          description='Marca del producto.'
          flexline_name = ''/>
        </div>

        <div className='col-md-6'>
          <SelectAtribute 
          id='id_input_space_type' 
          options={spaces.data} 
          title='Tipo de espacio' 
          name='space_type' 
          description='Indica el lugar deberia establecerse el producto'
          flexline_name = 'analisisproducto29'/>
        </div>

        <div className='col-md-6'>
          <InputTextAttribute title='Colección' name='collection' id='id_input_collection' type='text'
          description=''
          flexline_name='analisisproducto16'/>
        </div> 

        <div className='col-md-6'>
          <SelectAtribute 
          id='id_input_size' 
          options={sizes.data} 
          title='Tamaño' 
          name='size' 
          description='Indica el tamaño del producto'
          flexline_name = ''/>
        </div>

        <div className='col-md-6'>
          <SelectAtribute 
          id='id_input_package_type' 
          options={packages.data} 
          title='Tipo de Empaque' 
          name='package_type' 
          description='Indica el peso del bulto de origen(por caja)'
          flexline_name = ''/>
        </div>

        <div className='col-md-6 my-3'>
          <SwitchRadioButton
          id='id_input_vein' 
          value= {false}
          title = 'Veta'
          name='vein'
          description='Indica si tiene veta.'
          flexline_name = '' 
          />
        </div>

        <div className='col-md-12'>
          <TrixDescription title='Descripción' name='description'
          description='Descripción extenso del producto' 
          getDesc={this.props.getDesc}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requiredSheet: state.elementRequiredReducer.sheet
})

const mapDispatchToProps = (dispach) => ({
})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Sheet)

export default component;